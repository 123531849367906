import React, { useState, useEffect, useRef } from "react";
import { Space, Button, Typography, Input, message, Spin, Card, Alert, Modal, Upload, Tooltip } from "antd";
import { isLogged, getAuthToken, destroyAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";
import { formatPolishDate } from "../../../helpers/date";
import {
    FieldTimeOutlined,
    FileAddOutlined
} from "@ant-design/icons";
import AvatarDisplay from "../../Dashboard/Account/AvatarDisplay";
import DOMPurify from 'dompurify';

const ChatWindow = (props) => {
    const [loading, setLoading] = useState(false);
    const [loadingAI, setLoadingAI] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const [uploading, setUploading] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    const { TextArea } = Input;
    const { Text, Link } = Typography;
    const [attachmentFile, setAttachmentFile] = useState(null); // For storing the selected file
    const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state

    // Ref for the placeholder div
    const messagesEndRef = useRef(null);

    /**
     * Scrolls to the bottom of the message container
     */
    const scrollToBottom = () => {
        if (!isScrolling && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };


    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(",")[1]);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleUploadAttachment = async () => {
        try {
            if (!attachmentFile) {
                message.error("Nie wybrano pliku.");
                return;
            }

            setUploading(true);
            const base64File = await convertFileToBase64(attachmentFile);

            const response = await fetch(`${api.host}/attachment`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ attachmentFile: base64File }),
            });

            if (!response.ok) {
                throw new Error("Błąd przesyłania pliku.");
            }

            const data = await response.json();

            // Construct and send the message with the file link
            const uploadMessage = `Przesyłam plik: <a href="${data.publicUrl}" target="_blank">Pobierz</a>. <i>(Pliki ZIP mogą zawierać różne rodzaje danych, dlatego warto je sprawdzać zaufanym oprogramowaniem, aby upewnić się, że są bezpieczne.)</i>`;

            const apiUrl = `${api.host}/transactions/chats`;
            const sendResponse = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: getAuthToken(),
                },
                body: JSON.stringify({
                    uuid: props.transactionUuid,
                    message: uploadMessage,
                }),
            });

            if (sendResponse.ok) {
                message.success("Plik został przesłany i wiadomość została wysłana.");
                setMessageSent(true);
                scrollToBottom();
            } else {
                const errorData = await sendResponse.json();
                message.error(errorData.error || "Błąd podczas wysyłania wiadomości.");
            }
        } catch (error) {
            console.error("Error uploading attachment:", error);
            message.error("Błąd podczas przesyłania załącznika.");
        } finally {
            setUploading(false);
            setIsModalVisible(false); // Close the modal
        }
    };


    const handleFileChange = (info) => {
        const file = info.file.originFileObj || info.file;
        if (!file) {
            message.error("Nie udało się załadować pliku. Spróbuj ponownie.");
            return;
        }

        // Validate file type
        if (file.type !== "application/zip" && !file.name.endsWith(".zip")) {
            message.error("Możesz przesłać tylko pliki ZIP.");
            return;
        }

        setAttachmentFile(file);
        setIsModalVisible(true); // Show the confirmation modal
    };

    const handleCancelUpload = () => {
        setAttachmentFile(null);
        setIsModalVisible(false);
        message.info("Przesyłanie pliku zostało anulowane.");
    };

    /**
     * 
     */
    const handleAssistantButtonClick = async () => {
        setMessageInput('@TaskizyBot');
    };

    /**
     * Handles sending a message
     * @returns {void}
     */
    const handleSendMessage = async () => {
        if (!messageInput) {
            return;
        }

        setLoading(true);
        const apiUrl = `${api.host}/transactions/chats`;

        try {
            // Check if the message contains "@assistant"
            const mentionRegex = /@TaskizyBot/gi;
            if (mentionRegex.test(messageInput)) {
                // Call the AI Assistant endpoint
                setLoadingAI(true);
                const assistantResponse = await fetch(`${api.host}/chatbot/transaction-assistant`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: getAuthToken(),
                    },
                    body: JSON.stringify({
                        transactionUuid: props.transactionUuid,
                        message: messageInput.replace(mentionRegex, "").trim(),
                    }),
                });

                if (assistantResponse.ok) {
                    const assistantData = await assistantResponse.json();


                    // Send the message as usual if no mention
                    const response = await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: getAuthToken(),
                        },
                        body: JSON.stringify({
                            uuid: props.transactionUuid,
                            message: assistantData,
                        }),
                    });

                    if (response.ok) {
                        message.success("Wirtualny asystent odpowiedział na Twoją wiadomość.");
                        setMessageInput('');
                        setMessageSent(true);
                        scrollToBottom();
                        setLoadingAI(false);
                    } else {
                        const errorData = await response.json();
                        message.error(errorData.error);
                        setLoadingAI(false);
                    }


                } else {
                    const errorData = await assistantResponse.json();
                    message.error(errorData.error || "Błąd podczas komunikacji z AI Assistant.");
                }
            } else {
                // Send the message as usual if no mention
                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: getAuthToken(),
                    },
                    body: JSON.stringify({
                        uuid: props.transactionUuid,
                        message: messageInput,
                    }),
                });

                if (response.ok) {
                    message.success("Wiadomość wysłana");
                    setMessageInput('');
                    setMessageSent(true);
                    scrollToBottom();
                } else {
                    const errorData = await response.json();
                    message.error(errorData.error);
                }
            }
        } catch (error) {
            console.error("Error sending message:", error);
            message.error("An error occurred while sending the message.");
        } finally {
            setLoading(false);
        }
    };


    /**
     * Fetches messages from the server
     * @returns {void}
     */
    const fetchMessages = async () => {
        if (props.transactionUuid || messageSent) {
            const apiUrl = `${api.host}/transactions/chats/${props.transactionUuid}`;

            try {
                const response = await fetch(apiUrl, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: `${getAuthToken()}`,
                    }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setMessages(data);
                    setLoading(false);
                } else {
                    throw new Error('Failed to fetch messages');
                }
            } catch (error) {
                setTimeout(() => {
                    message.error("Dla bezpieczeństwa zostałeś wylogowany.");
                    destroyAuthToken();
                    window.location.href = "/transactions";
                }, 2000);
                setLoading(false);
            }
        }
    };

    // Fetch messages when component mounts and set interval for periodic updates
    useEffect(() => {
        fetchMessages();
        const intervalId = setInterval(fetchMessages, 5000);
        return () => clearInterval(intervalId);
    }, [props.transactionUuid, messageSent]);

    /**
     * Handles scrolling event
     * @returns {void}
     */
    const handleWheel = () => {
        setIsScrolling(true);
        // Clear the scrolling state after a delay
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
            setIsScrolling(false);
        }, 1500);
    };

    let scrollTimeout;

    return (
        <>
            <Spin spinning={loading || loadingAI}>
                <Space direction="vertical" style={{ width: "100%" }}>
                    {/* Top div with gradient background */}
                    <div
                        style={{
                            top: "0",
                            height: "50px",
                            position: "absolute",
                            background: "linear-gradient('#aaa', 'transparent')",
                            opacity: "0.9",
                            width: "100%",
                            zIndex: "1000",
                            borderRadius: "5px"
                        }}
                    >&nbsp;</div>

                    {/* Container div for messages */}
                    <div
                        id="chat"
                        style={{ maxHeight: messages.length == 0 ? "100px" : "230px", overflowY: "scroll", paddingBottom: "50px", width: "100%" }}
                        onWheel={handleWheel}
                    >
                        <style>
                            {`
            #chat::-webkit-scrollbar {
                display: none; /* Hide the scrollbar */
            }
        `}
                        </style>
                        <Space direction="vertical" size={2} style={{ width: "100%" }}>
                            {/* Map through messages and render each as a Card */}
                            {messages.length == 0 ? <Alert showIcon message="W czasie trwania transakcji nie zostały wymienione jeszcze żadne wiadomości." type="info" /> : ''}
                            {messages.map((msg) => (
                                <>
                                    <Card
                                        style={{
                                            background: sessionStorage.getItem("uuid") === msg.account.uuid ? "#000" : "#0f0f0f",
                                            width: sessionStorage.getItem("uuid") === msg.account.uuid ? "100%" : "90%",
                                            margin: sessionStorage.getItem("uuid") === msg.account.uuid ? "10px" : "0px",
                                        }}
                                    >
                                        <Space size={1} direction="vertical">
                                            <Space size={5} style={{ fontSize: "75%" }}>
                                                <AvatarDisplay src={msg.account.photo} size={16} />
                                                <Text>
                                                    <nobr>{msg.account.fullname}</nobr>
                                                </Text>
                                                <FieldTimeOutlined /> {formatPolishDate(msg.created_at)}
                                            </Space>
                                        </Space>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(msg.message.replace(/\\n/g, '<br/>'), {
                                                    ALLOWED_TAGS: ['a', 'i', 'br'], // Allow only <a> tags
                                                    ALLOWED_ATTR: ['href', 'target', 'rel'], // Allow specific attributes on <a>
                                                })
                                            }}
                                        ></p>
                                    </Card>
                                </>
                            ))}
                            {/* Placeholder div for scrolling */}
                            <div ref={messagesEndRef} />
                        </Space>
                    </div>
                    <Card>
                        <Space direction="vertical" size={10} style={{ width: "100%" }}>

                            {/* Textarea for message input */}
                            <TextArea
                                placeholder="Wpisz wiadomość..."
                                autoSize={{
                                    minRows: 2,
                                    maxRows: 6,
                                }}
                                value={messageInput}
                                onChange={(e) => {
                                    setMessageInput(e.target.value);
                                    if (messageSent) { setMessageSent(false) };
                                }}
                            />
                            {/* Button to send message */}
                            <Space>
                                <Upload
                                    accept=".zip"
                                    beforeUpload={() => false}
                                    onChange={handleFileChange}
                                    showUploadList={false}
                                >
                                    <Button type="default" icon={<FileAddOutlined />}></Button>
                                </Upload>
                                <Tooltip placement="bottom" title="Podsumuj rozmowę oraz ustalenia z asystentem AI." open>
                                    <Button type="default" onClick={handleAssistantButtonClick}>
                                        @TaskizyBot
                                    </Button>
                                </Tooltip>
                                <Button type="primary" onClick={handleSendMessage}>Wyślij</Button>
                            </Space>
                        </Space>
                    </Card>
                </Space>
            </Spin>
            <Modal
                title="Potwierdzenie przesłania pliku"
                visible={isModalVisible}
                onOk={handleUploadAttachment}
                onCancel={handleCancelUpload}
                confirmLoading={uploading}
            >
                <p>Czy na pewno chcesz przesłać plik: <strong>{attachmentFile?.name}</strong>?</p>
            </Modal>
        </>
    );
};

export default ChatWindow;
