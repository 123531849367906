import React, { useState, useEffect } from "react";
import {
  Space,
  Button,
  Typography,
  Input,
  List,
  message,
  Spin,
  Modal,
  Tag,
} from "antd";
import { PlusOutlined, DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import { api } from "../../../config/api";
import { getAuthToken } from "../../../helpers/account";

const TodoList = ({ transactionUuid }) => {
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState("");
  const [deletingTask, setDeletingTask] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { Text } = Typography;

  /**
   * Fetch tasks from the API
   */
  const fetchTasks = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${api.host}/tasks/${transactionUuid}`, {
        method: "GET",
        headers: {
          Authorization: getAuthToken(),
        },
      });

      if (response.ok) {
        const data = await response.json();
        setTasks(data);
      } else {
        throw new Error("Nie udało się pobrać zadań.");
      }
    } catch (error) {
      console.error("Błąd pobierania zadań:", error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Add a new task
   */
  const addTask = async () => {
    if (!newTask.trim()) {
      message.error("Zadanie nie może być puste.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${api.host}/tasks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
        body: JSON.stringify({
          transactionUuid: transactionUuid,
          task: newTask,
          order_by: tasks.length + 1, // Example ordering logic
        }),
      });

      if (response.ok) {
        message.success("Zadanie dodane.");
        setNewTask("");
        fetchTasks();
      } else {
        throw new Error("Nie udało się dodać zadania.");
      }
    } catch (error) {
      console.error("Błąd dodawania zadania:", error);
      message.error("Nie udało się dodać zadania.");
    } finally {
      setLoading(false);
    }
  };

  /**
   * Delete a task
   */
  const deleteTask = async (uuid) => {
    setLoading(true);
    try {
      const response = await fetch(`${api.host}/tasks/${uuid}`, {
        method: "DELETE",
        headers: {
          Authorization: getAuthToken(),
        },
      });

      if (response.ok) {
        message.success("Zadanie usunięte.");
        fetchTasks();
      } else {
        throw new Error("Nie udało się usunąć zadania.");
      }
    } catch (error) {
      console.error("Błąd usuwania zadania:", error);
      message.error("Nie udało się usunąć zadania.");
    } finally {
      setLoading(false);
    }
  };

  /**
   * Toggle task status (completed / not completed)
   */
  const toggleTaskStatus = async (task) => {
    setLoading(true);
    try {
      const response = await fetch(`${api.host}/tasks/${task.uuid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
        body: JSON.stringify({
          is_finished: !task.is_finished,
        }),
      });

      if (response.ok) {
        message.success("Status zadania zaktualizowany.");
        fetchTasks();

        // Construct and send the message with the file link
        const uploadMessage = `Zaktualizowałem status zadania: "${
          task.task
        }" na "${!task.is_finished ? "Ukończone" : "Nieukończone"}".`;

        const apiUrl = `${api.host}/transactions/chats`;
        const sendResponse = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
          body: JSON.stringify({
            uuid: transactionUuid,
            message: uploadMessage,
          }),
        });

        if (sendResponse.ok) {
        } else {
          const errorData = await sendResponse.json();
          message.error(
            errorData.error || "Błąd podczas wysyłania wiadomości."
          );
        }
      } else {
        throw new Error("Nie udało się zmienić statusu zadania.");
      }
    } catch (error) {
      console.error("Błąd zmiany statusu zadania:", error);
      message.error("Nie udało się zmienić statusu zadania.");
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle task deletion confirmation
   */
  const confirmDeleteTask = (task) => {
    setDeletingTask(task);
    setIsModalVisible(true);
  };

  /**
   * Handle task deletion cancellation
   */
  const cancelDeleteTask = () => {
    setDeletingTask(null);
    setIsModalVisible(false);
  };

  /**
   * Fetch tasks on component mount or when transactionUuid changes
   */
  useEffect(() => {
    fetchTasks();
  }, [transactionUuid]); // Add transactionUuid as dependency

  return (
    <Spin spinning={loading}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space>
          <Input
            placeholder="Dodaj nowe zadanie"
            value={newTask}
            onChange={(e) => setNewTask(e.target.value)}
            onPressEnter={addTask}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={addTask}>
            Dodaj
          </Button>
        </Space>
        <List
          bordered
          locale={{
            emptyText: "Brak dodanych zadań",
          }}
          dataSource={tasks}
          renderItem={(item) => (
            <List.Item>
              <Space direction="vertical">
                <Text>{item.task}</Text>

                <Space>
                  <Button
                    danger
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => confirmDeleteTask(item)}
                  >
                    Usuń
                  </Button>
                  <Button type="text" onClick={() => toggleTaskStatus(item)}>
                    {item.is_finished === true ? (
                      <Tag color="green-inverse">Ukończone</Tag>
                    ) : (
                      <Tag color="red-inverse">Nieukończone</Tag>
                    )}
                  </Button>
                </Space>
              </Space>
            </List.Item>
          )}
        />
      </Space>
      <Modal
        title="Potwierdzenie usunięcia"
        visible={isModalVisible}
        onOk={() => {
          if (deletingTask) deleteTask(deletingTask.uuid);
          setIsModalVisible(false);
        }}
        onCancel={cancelDeleteTask}
      >
        <p>
          Czy na pewno chcesz usunąć zadanie:{" "}
          <strong>{deletingTask?.task}</strong>?
        </p>
      </Modal>
    </Spin>
  );
};

export default TodoList;
