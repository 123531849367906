import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoginPage from "../../../Login/LoginPage";
import { Table, Card, Space, Row, Col, Breadcrumb, Divider, Typography, Alert, Select, Button, message, Input } from "antd";
import { isLogged, getAuthToken } from "../../../../helpers/account";
import { api } from "../../../../config/api";
import { HomeOutlined } from "@ant-design/icons";
const { Title } = Typography;
const { TextArea } = Input;

const categoryColumns = [
    {
        title: "Nazwa",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        sorter: true,
        render: (name, record) => (
            <>
                <Link to={`/account/offer/category/${record.uuid}`}>{name}</Link>
            </>
        ),
    },
];

const accountProductColumns = [
    {
        title: "Produkt / Usługa z listingu Taskizy.com",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        sorter: true,
        render: (name, record) => (
            <>
                <Link to={`/account/offer/product/${record.product.uuid}`}>{record.product.name}</Link>
            </>
        ),
    },
];

function AccountCategoriesPage() {
    const [data, setData] = useState([]);
    const [accountProducts, setAccountProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tags, setTags] = useState([]); // All available tags
    const [selectedTags, setSelectedTags] = useState([]); // Tags selected by the user
    const [accountTags, setAccountTags] = useState([]); // Tags fetched from /tags/account
    const [accountDescription, setAccountDescription] = useState(""); // Account description

    // Fetch categories, account products, tags, and description
    useEffect(() => {
        if (isLogged()) {
            fetch(`${api.host}/categories`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `${getAuthToken()}`,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setData(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching categories data:", error);
                    setLoading(false);
                });

            fetch(`${api.host}/accounts/products`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `${getAuthToken()}`,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setAccountProducts(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching account products data:", error);
                    setLoading(false);
                });

            // Fetch all available tags
            fetch(`${api.host}/tags`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `${getAuthToken()}`,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    const tagOptions = data.map((tag) => ({
                        value: tag.uuid, // UUID for tag value
                        label: tag.name,  // Name as label
                    }));
                    setTags(tagOptions); // All possible tags
                })
                .catch((error) => {
                    console.error("Error fetching all tags:", error);
                });

            // Fetch the tags associated with the logged-in account
            fetch(`${api.host}/tags/account`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `${getAuthToken()}`,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    const selectedTagValues = data.map(tag => tag.uuid); // Extract UUIDs of the tags
                    setAccountTags(data); // Store the tags associated with the account
                    setSelectedTags(selectedTagValues); // Set the selected tags' UUIDs
                })
                .catch((error) => {
                    console.error("Error fetching account tags:", error);
                });

            // Fetch the account description
            fetch(`${api.host}/accounts/${sessionStorage.getItem("uuid")}`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `${getAuthToken()}`,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setAccountDescription(data.description || ""); // Set account description
                })
                .catch((error) => {
                    console.error("Error fetching account description:", error);
                });
        }
    }, []);

    // Handle tag selection
    const handleTagChange = (value) => {
        setSelectedTags(value); // Update selected tags with UUIDs
    };

    // Update the tags for the logged-in account
    const updateTags = () => {
        fetch(`${api.host}/tags/account`, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `${getAuthToken()}`,
            }),
            body: JSON.stringify({
                tagUuids: selectedTags, // Sending UUIDs of selected tags
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                message.success("Lista tagów została zapisana.");
            })
            .catch((error) => {
                console.error("Error updating tags:", error);
            });
    };

    // Handle description change
    const handleDescriptionChange = (e) => {
        setAccountDescription(e.target.value); // Update account description
    };

    // Save the account description
    const saveDescription = () => {
        fetch(`${api.host}/accounts/${sessionStorage.getItem("uuid")}`, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `${getAuthToken()}`,
            }),
            body: JSON.stringify({
                description: accountDescription, // Send updated description
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                message.success("Opis został zapisany.");
            })
            .catch((error) => {
                console.error("Error saving account description:", error);
            });
    };

    if (isLogged()) {
        return (
            <>
                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "15px 15px" }}>
                        <Space direction="vertical">
                            <Title level={2}>Moja oferta</Title>
                            <Breadcrumb
                                items={[
                                    {
                                        href: "/",
                                        title: <HomeOutlined />,
                                    },
                                    {
                                        title: "Moja oferta",
                                    },
                                ]}
                            />
                        </Space>
                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Divider></Divider>

                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "0px 15px" }}>
                        <Space size={30} direction="vertical" style={{ width: "100%" }}>
                            <Card size="small">
                                <Space direction="vertical">
                                    <Title level={5}>Twoje tagi</Title>
                                    <p>Opisz swój profil za pomocą tagów, które najlepiej odzwierciedlają Twoje umiejętności i doświadczenie. Będziemy filtrować profile według tych tagów, aby zleceniodawcy mogli łatwiej znaleźć Ciebie i Twoje kompetencje.</p>
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        placeholder="Wybierz tagi"
                                        onChange={handleTagChange}
                                        value={selectedTags} // Pre-fill selected tags (UUIDs)
                                        options={tags} // Options populated with all available tags
                                    />
                                    <Button type="primary" onClick={updateTags}>Zapisz tagi</Button>
                                </Space>
                            </Card>
                        </Space>
                    </Col>
                    <Col md={4}></Col>
                </Row>

                <Divider></Divider>

                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "0px 15px" }}>
                        <Space size={30} direction="vertical" style={{ width: "100%" }}>
                            <Card size="small">
                                <Title level={5}>Opis profilu</Title>
                                <TextArea
                                    maxLength={500}
                                    rows={8}
                                    value={accountDescription}
                                    onChange={handleDescriptionChange}
                                    placeholder="Wprowadź opis konta"
                                />
                                <Button type="primary" onClick={saveDescription} style={{ marginTop: "10px" }}>
                                    Zapisz opis
                                </Button>
                            </Card>
                        </Space>
                    </Col>
                    <Col md={4}></Col>
                </Row>

                <Divider></Divider>

                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "0px 15px" }}>
                        <Space size={30} direction="vertical" style={{ width: "100%" }}>
                            <Card size="small">
                                <Table
                                    pagination={{ pageSize: 5 }}
                                    className="shadow"
                                    columns={accountProductColumns}
                                    dataSource={accountProducts}
                                    loading={loading}
                                    locale={{
                                        emptyText: "Nie oferujesz żadnych produktów i usług z listingu Taskizy.com",
                                    }}
                                />
                            </Card>
                        </Space>
                    </Col>
                    <Col md={4}></Col>
                </Row>

                <Divider></Divider>

                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "0px 15px" }}>
                        <Space size={30} direction="vertical" style={{ width: "100%" }}>
                            <Alert
                                showIcon
                                message="Wybierz kategorie usług, które oferujesz, a następnie aktywuj swoje oferty w katalogu taskizy.com, aby zainteresowani klienci mogli je u Ciebie zamawiać. Proces aktywacji jest całkowicie darmowy. Oferty automatycznie pojawią się również na Twoim profilu publicznym."
                            ></Alert>
                            <Card size="small">
                                <Table
                                    pagination={{ pageSize: 5 }}
                                    className="shadow"
                                    columns={categoryColumns}
                                    dataSource={data}
                                    loading={loading}
                                    locale={{
                                        emptyText: "Brak kategorii usług",
                                    }}
                                />
                            </Card>
                        </Space>
                    </Col>
                    <Col md={4}></Col>
                </Row>
            </>
        );
    } else {
        return <LoginPage />;
    }
}

export default AccountCategoriesPage;
