import React, { useEffect, useState } from 'react';
import locale from 'antd/locale/pl_PL';
import './App.css';
import TransactionsPage from './components/Dashboard/TransactionsPage';
import AccountPage from './components/Dashboard/AccountPage';
import { Routes, Link, Route, useLocation } from 'react-router-dom';
import {
  Layout,
  Menu,
  ConfigProvider,
  Button,
  Dropdown,
  Divider,
  Row,
  Col,
  Space,
  theme
} from 'antd';
import TransactionPage from './components/Transaction/TransactionPage';
import TransactionsAdminPage from './components/Dashboard/TransactionsAdminPage';
import BankAccountsPage from "./components/Dashboard/BankAccountsPage";
import WelcomePage from './components/Offer/WelcomePage';
import RegisterPage from './components/Register/RegisterPage';
import PricingPage from './components/Offer/PricingPage';
import JobPage from './components/Offer/JobPage';
import FeaturesPage from './components/Offer/FeaturesPage';
import CreateTransactionPage from './components/Offer/CreateTransactionPage';
import AboutPage from './components/Offer/AboutPage';
import SocialResponsibilityPage from './components/Offer/SocialResponsibilityPage';
import PasswordResetPage from './components/Login/PasswordResetPage';
import AcceptInvitationPage from './components/Login/AcceptInvitationPage';
import AccountDataPage from './components/Dashboard/Account/Data/AccountDataPage';
import ContactPage from './components/Offer/ContactPage';
import RegulationsPage from './components/Offer/RegulationsPage';
import AccountCategoriesPage from './components/Dashboard/Account/Offer/AccountCategoriesPage';
import AccountProductsCategoriesPage from './components/Dashboard/Account/Offer/AccountProductsCategoriesPage';
import AccountProductPage from './components/Dashboard/Account/Offer/AccountProductPage';
import MarketplaceProductPage from './components/Marketplace/MarketplaceProductPage';
import MarketplaceProductsCategoriesPage from './components/Marketplace/MarketplaceProductsCategoriesPage';
import { HelmetProvider } from 'react-helmet-async';
import {
  LockTwoTone
} from "@ant-design/icons";
import OffersPage from './components/Dashboard/OffersPage';
import JoinUsPage from './components/Offer/JoinUsPage';
import InquiresPage from './components/Dashboard/InquiresPage';
import InquiryPage from './components/Inquiry/InquiryPage';
import MarketplaceInquiryPage from './components/Marketplace/MarketplaceInquiryPage';
import MarketplaceInquiriesPage from './components/Marketplace/MarketplaceInquiriesPage';
import MarketplaceAccountProfilePage from './components/Marketplace/MarketplaceAccountProfilePage';
import AccountMissingInfo from './components/Dashboard/Account/Data/AccountMissingInfo';
import AccountShortInfo from './components/Dashboard/Account/Data/AccountShortInfo';
import AssetsPage from './components/Dashboard/AssetsPage';
import AssetPage from './components/Asset/AssetPage';
import MarketplaceAssetPage from './components/Marketplace/MarketplaceAssetPage';
import MarketplaceAssetsPage from './components/Marketplace/MarketplaceAssetsPage';
import MarketplaceFreelancersPage from './components/Marketplace/MarketplaceFreelancersPage';
import TaskizybotPage from './components/Offer/TaskbotPage';
import { isLogged } from './helpers/account';
import AddInquiryPage from './components/Offer/AddInquiryPage';
const { Content, Header, Footer } = Layout;


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('authToken'));
  const [isRootPath, setIsRootPath] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();

  const handleLogout = () => {
    sessionStorage.clear();
    setIsLoggedIn(false);
  }

  useEffect(() => {
    setIsRootPath(window.location.pathname === "/");
  });

  // Scroll to top effect when the URL changes
  useEffect(() => {
    window.scrollTo(0, 0); // This will scroll the page to the top
    if (location.pathname !== '/transactions' && location.pathname !== '/account/register' && isLogged() === false) {
      sessionStorage.setItem('lastVisitedPath', location.pathname);
    }

  }, [location]);

  const menuAccount = (
    <Menu>
      <Menu.Item key="6">
        <Link to="/account/offer">Moja oferta</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to="/account">Moje dane</Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Link to="/" onClick={handleLogout}>
          Wyloguj
        </Link>
      </Menu.Item>
    </Menu>
  );

  const websiteAccount = (
    <Menu>
      <Menu.Item>
        <Link to="/marketplace/inquiries">Pozyskaj zlecenie</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/marketplace/freelancers">Znajdź wykonawcę</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/add">Dodaj zlecenie</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/marketplace/assets">Sprzedaj stronę</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to="/join">Dla freelancera</Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Link to="/taskizybot">
          TaskizyAI
        </Link>
      </Menu.Item>
      <Menu.Item key="6">
        <Link to="/pricing">
          Prowizje
        </Link>
      </Menu.Item>
      <Menu.Item key="6">
        <Link to="/about">O nas</Link>
      </Menu.Item>
    </Menu>
  );

  const menuInquiries = (
    <Menu>
      <Menu.Item key="8">
        <Link to="/account/offer">Złożone</Link>
      </Menu.Item>
      <Menu.Item key="9">
        <Link to="/account">Biorę udział</Link>
      </Menu.Item>
    </Menu>
  );


  return (
    <HelmetProvider>
      <ConfigProvider
        locale={locale}
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            // Seed Token
            colorPrimary: '#FF6200',
            // borderRadius: '10px',
            fontFamily: 'Livvic',
            colorLink: '#fff',
          },
        }}
      >

        <Layout>

          {isLoggedIn ? (
            <Header >
              <Menu mode="horizontal" defaultSelectedKeys={['1']} style={{ display: 'flex', background: "transparent" }}>
                <div className="logo"><Link to="/"><h2 className='brand'><img style={{
                  height: "35px",
                  marginLeft: "-51px",
                  marginTop: "-6px"
                }} src='/img/svg/logo-part.svg' /></h2></Link></div>
                <Menu.Item key="1">
                  <Link to="/transactions">Moje transakcje</Link>
                </Menu.Item>
                <Menu.Item key="5">
                  <Link to="/assets">Moja aktywa</Link>
                </Menu.Item>
                <Menu.Item key="6">
                  <Link to="/inquiries">Moje zapytania</Link>
                </Menu.Item>

                <Menu.Item key="2">
                  <Link to="/offers">Zamów usługę</Link>
                </Menu.Item>
                <Menu.Item key="7">
                  <Link to="/marketplace/inquiries">Znajdź zlecenie</Link>
                </Menu.Item>
                <Menu.Item key="8">
                  <Link to="/marketplace/assets">Kup aktywo</Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Dropdown overlay={menuAccount}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                      Konto
                    </a>
                  </Dropdown>
                </Menu.Item>
              </Menu>
            </Header>
          ) : <Header>
            <Menu mode="horizontal" style={{ display: 'flex', background: "transparent" }}>
              <div className="logo" style={{ marginRight: "50px" }}><Link to="/"><h2 className='brand'><img style={{

                left: "8px",

              }} src='/img/svg/logo.svg' /></h2></Link></div>
              {!isMobile && <> <Menu.Item>
                <Link to="/marketplace/inquiries">Pozyskaj zlecenie</Link>
              </Menu.Item>
                <Menu.Item>
                  <Link to="/marketplace/freelancers">Znajdź wykonawcę</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/add">Dodaj zlecenie</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/marketplace/assets">Sprzedaj stronę</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/join">Dla freelancera</Link>
                </Menu.Item>

                <Menu.Item>
                  <Link to="/taskizybot">TaskizyAI</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/pricing">Prowizje</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/about">O nas</Link>
                </Menu.Item></>}

              {isMobile && <><Menu.Item style={{ background: "transparent", right: "130px", top: "7px", position: "absolute", zIndex: 1000 }} key="1">
                <Dropdown overlay={websiteAccount}>
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <img src="/img/svg/30.svg" />
                  </a>
                </Dropdown>
              </Menu.Item></>}


              <Menu.Item style={{ background: "transparent", right: "11px", position: "absolute", zIndex: 1000 }} key="3">
                <Link to="/transactions">
                  <Button size='middle'><LockTwoTone />Konto</Button>
                </Link>
              </Menu.Item>
            </Menu>
          </Header>
          }

          <Content>

            {isLoggedIn ? <AccountMissingInfo /> : ""}
            {isLoggedIn ? <AccountShortInfo /> : ""}

            <Routes>
              <Route path="/" element={<WelcomePage />} />
              <Route path="/create-transaction" element={<CreateTransactionPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/join" element={<JoinUsPage />} />
              <Route path="/add" element={<AddInquiryPage />} />
              <Route path="/features" element={<AboutPage />} />
              <Route path="/job" element={<JobPage />} />
              <Route path="/regulations" element={<RegulationsPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/social-responsibility" element={<SocialResponsibilityPage />} />
              <Route path="/transactions" element={<TransactionsPage />} />
              <Route path="/assets" element={<AssetsPage />} />
              <Route path="/inquiries" element={<InquiresPage />} />
              <Route path="/offers" element={<OffersPage />} />
              <Route path="/admin/transactions" element={<TransactionsAdminPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/account/data" element={<AccountDataPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/taskizybot" element={<TaskizybotPage />} />
              <Route path="/account/register" element={<RegisterPage />} />
              <Route path="/account/password-reset" element={<PasswordResetPage />} />
              <Route path="/account/accept-invitation/:invitationUuid" element={<AcceptInvitationPage />} />
              <Route path="/account/bank-accounts" element={<BankAccountsPage />} />
              <Route path="/account/offer" element={<AccountCategoriesPage />} />
              <Route path="/account/offer/category/:categoryUuid" element={<AccountProductsCategoriesPage />} />
              <Route path="/account/offer/product/:productUuid" element={<AccountProductPage />} />
              <Route path="/transaction/:transactionUuid" element={<TransactionPage />} />
              <Route path="/inquiry/:inquiryUuid" element={<InquiryPage />} />
              <Route path="/asset/:assetUuid" element={<AssetPage />} />
              <Route path="/marketplace/category/:categoryUuid" element={<MarketplaceProductsCategoriesPage />} />
              <Route path="/marketplace/product/:productUuid" element={<MarketplaceProductPage />} />
              <Route path="/marketplace/inquiry/:inquiryUuid" element={<MarketplaceInquiryPage />} />
              <Route path="/marketplace/asset/:assetUuid" element={<MarketplaceAssetPage />} />
              <Route path="/marketplace/inquiries" element={<MarketplaceInquiriesPage />} />
              <Route path="/marketplace/assets" element={<MarketplaceAssetsPage />} />
              <Route path="/marketplace/freelancers" element={<MarketplaceFreelancersPage />} />
              <Route path="/marketplace/freelancers/:tagFromUrl" element={<MarketplaceFreelancersPage />} />
              <Route path="/profile/:accountUuid" element={<MarketplaceAccountProfilePage />} />


            </Routes>
            <Divider></Divider>
            <Row style={{ padding: "25px 0px" }}>
              <Col md={4}>
              </Col>
              <Col md={6} style={{ padding: "0px 25px" }}>
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  <li>
                    <Link to="/about">O nas</Link>
                  </li>
                  <li>
                    <Link to="/join">Oferta dla usługodawców</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Cennik</Link>
                  </li>
                  <li>
                    <Link to="/taskizybot">TaskizyAI</Link>
                  </li>
                  <li>
                    <Link to="/account/register">Załóż darmowe konto</Link>
                  </li>
                  <li>
                    <Link to="/marketplace/inquiries">Pozyskaj zlecenie</Link>
                  </li>
                  <li>
                    <Link to="/offers">Zamów usługę</Link>
                  </li>
                </ul>
                <br />
              </Col>
              <Col md={6} style={{ padding: "0px 25px" }}>
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  <li>
                    <Link to="/marketplace/freelancers/WordPress">Programista WordPress</Link>
                  </li>
                  <li>
                    <Link to="/marketplace/freelancers/PHP">Programista PHP</Link>
                  </li>
                  <li>
                    <Link to="/marketplace/freelancers/ReactJS">Programista ReactJS</Link>
                  </li>
                  <li>
                    <Link to="/marketplace/freelancers/DevOps">Administrator serwerów</Link>
                  </li>
                  <li>
                    <Link to="/marketplace/freelancers/SEO">Pozycjonowanie stron</Link>
                  </li>
                </ul>
                <br />
              </Col>

              <Col md={4} style={{ padding: "0px 25px" }}>
                <div><b>Pomoc i współpraca</b></div>
                <div>Kontakt: +48 530 858 861</div>
                <div>kontakt@postpay.pl</div>

              </Col>

              <Col md={4}>
              </Col>
            </Row>
          </Content>

        </Layout>


      </ConfigProvider >
    </HelmetProvider>
  );
};

export default App;