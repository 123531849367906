import {
  Button,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Divider,
  Card,
  Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { RoughNotation } from "react-rough-notation";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import HelpBox from "./HelpBox";
const { Title } = Typography;

const AddInquiryPage = () => {
  const [visible, setVisible] = useState(null);

  return (
    <>
      {" "}
      <Helmet>
        <title>Zacznij sprzedawać swoje usługi z taskizy.com</title>
      </Helmet>
      <Row justify="space-between" align="middle" gutter={12}>
        <Col md={4}></Col>
        <Col md={8} style={{ padding: "0px 15px" }}>
          <Space direction="vertical">
            <Title level={2}>Dodaj ogłoszenie za darmo z pomocą AI</Title>
          </Space>
        </Col>
        <Col md={8} style={{ textAlign: "center", padding: "15px" }}>
          <br />
          <video
            style={{ width: "100%" }}
            autoPlay="true"
            controls
            src="/img/Screencast from 14.01.2025 23:14:39.webm"
          ></video>
        </Col>
        <Col md={4}></Col>
      </Row>
      <Row align={"top"}>
        <Col md={4}></Col>
        <Col md={8} style={{ padding: "0px 15px" }}>
          <Link to="/account/register">
            <Button size="large" type="primary">
              Utwórz profil za darmo
            </Button>
          </Link>
          <Space direction="vertical">
            <Title level={3}>
              Zawierając umowy na taskizy.com, zyskujesz pewność, że każda ze
              stron wywiąże się ze swoich zobowiązań
            </Title>
            <img
              src="/img/svg/16.svg"
              style={{ width: "100%", maxWidth: "330px" }}
            />
            <p>
              Twoje środki będą zabezpieczane od początku współpracy i
              wypłacane dopiero po zgodnym z umową wykonaniu pracy. Dzięki
              dostępowi do nowoczesnych narzędzi IT i przejrzystej komunikacji
              możesz realizować transakcje w bezpiecznym i w pełni
              transparentnym środowisku.
            </p>
            <Title level={3}>Jak zacząć zamawiać usługi na taskizy.com?</Title>
            <p>
              Dzięki platformie taskizy.com zyskasz dostęp do wykonawców, 
              pewność realizacji oraz pełną ochronę transakcji za pomocą
              systemu escrow. Dzięki wbudowanemu <Link to="/taskizybot">Asystentowi AI</Link> projektu
              nie pominiesz żadnego ważnego zapisu w umowie, a dodanie ogłoszenia nie sprawi problemu
              nawet mało technicznej osobie ponieważ tutaj również wspieramy Ciebie AI.
            </p>
          
          </Space>
        </Col>
        <Col md={8} style={{ textAlign: "center", padding: "15px 15px" }}>
          <Space size={30} direction="vertical">
           
            <HelpBox></HelpBox>
            <Link to="/marketplace/product/dc5d1ae3-1496-4e9d-b2b0-8b1fc8e77731">
              Zobacz przykład oferty
            </Link>
          </Space>
        </Col>
        <Col md={4}></Col>
      </Row>
    </>
  );
};

export default AddInquiryPage;
