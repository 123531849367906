import React, { useState, useEffect } from "react";
import { Card, Space, Tag, Typography, Progress } from "antd";
import { api } from "../../config/api";
import { formatPolishCurrency } from "../../helpers/currency";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";

const { Title } = Typography;

export const LastFiveInquiries = () => {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const style = {
    display: "flex",
    justifyContent: "center",
    marginTop: "50px",
  };

  useEffect(() => {
    const fetchInquiries = async () => {
      try {
        const response = await fetch(`${api.host}/inquiries/active`, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        });
        const inquiries = await response.json();
        setData(inquiries.slice(0, 5)); // Fetch the last 5 inquiries
      } catch (error) {
        console.error("Error fetching inquiries:", error);
      }
    };

    fetchInquiries();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const progressInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
            return 0; // Reset progress after reaching 100%
          }
          return prev + 10; // Increment progress
        });
      }, 200); // Update progress every 200ms (total 2 seconds for 100%)

      return () => clearInterval(progressInterval);
    }
  }, [data]);

  const content = data[currentIndex] ? (
    <div style={{ textAlign: "center" }}>
      <Title level={5} style={{ margin: 0 }}>
        {data[currentIndex].name}
      </Title>
      <Space direction="vertical" style={{ marginTop: 10, width: "100%" }}>
        <Tag>{data[currentIndex].account.company_name || "Osoba prywatna"}</Tag>
        <Space>
          <AvatarDisplay
            account={data[currentIndex].account}
            src={data[currentIndex].account.photo}
            size={32}
          />
          {data[currentIndex].account.fullname}
        </Space>
        <Tag color="gold-inverse">
          {formatPolishCurrency(data[currentIndex].budget)}
        </Tag>
      </Space>
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>
      <img src="/img/03-19-26-213_512.gif"
      style={{width:"20%"}}
      />
    </div>
  );

  return (
    <div style={style}>
      <Card
        bordered
        style={{ width: "100%", maxWidth: "400px" }}
        bodyStyle={{ textAlign: "center" }}
      >
        {content}
        <Progress
          percent={progress}
          showInfo={false}
          strokeColor="#1890ff"
          style={{ marginTop: 20 }}
        />
      </Card>
    </div>
  );
};

export default LastFiveInquiries;
