import React, { useState, useEffect } from "react";
import { Avatar, Typography, Space, Spin, Tooltip } from "antd";
import { api } from "../../config/api"; // Adjust the path to your API configuration
import {
    SmileTwoTone,
} from "@ant-design/icons";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";
import { RoughNotation } from "react-rough-notation";
const { Title, Text } = Typography;

const RecentAccountsList = ({limit}) => {
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRecentAccounts = async () => {
            try {
                const response = await fetch(`${api.host}/accounts/new`);
                if (!response.ok) {
                    throw new Error("Failed to fetch recent accounts.");
                }
                const data = await response.json();
                setAccounts(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching recent accounts:", error);
                setLoading(false);
            }
        };

        fetchRecentAccounts();
    }, []);

    return (
        <Spin spinning={loading}>
            <Space direction="vertical" style={{ width: "100%" }}>
        
                {accounts.length > 0 ? (
                    <Avatar.Group
                        maxCount={limit ? limit : 6}
                        size="large"

                    >
                        {accounts.map((account) => (
                            <>{account ? <AvatarDisplay account={account} src={account.photo} size={48} /> : ""}</>

                        ))}
                    </Avatar.Group>
                ) : (
                    <Text type="secondary">Brak nowo zarejestrowanych użytkowników.</Text>
                )}
            </Space>
        </Spin>
    );
};

export default RecentAccountsList;
