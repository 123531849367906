import React, { useState, useEffect } from "react";
import { api } from "../../config/api";
import {
    Space, Row, Col, Breadcrumb,
    Typography,
    Divider,
    Card,
    Button,
} from "antd";
import { Link, useParams } from "react-router-dom";
import {
    HomeOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet-async";
import { Assets } from "../Offer/AssetsPage"; // Assuming you have an Assets component to show asset list
const { Title } = Typography;

const MarketplaceAssetsPage = () => {

    return (
        <>
            <Helmet>
                <title>Katalog aktywów</title>
            </Helmet>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "15px 15px" }}>
                        <Space direction="vertical">

                            <Space direction="horizontal">

                                <img src="/img/svg/22.svg" style={{ width: "50px" }} />
                                <Title level={2}>
                                    Kup lub sprzedaj stronę
                                </Title>
                            </Space>
                            <Breadcrumb
                                items={[
                                    {
                                        href: "/",
                                        title: <HomeOutlined />,
                                    },
                                    {
                                        title: "Katalog aktywów",
                                    },
                                ]}
                            />
                        </Space>
                    </Col>
                    <Col md={4} style={{ textAlign: "center" }}>

                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Divider></Divider>
                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "0px 15px" }}>
                        <Space direction="vertical" style={{width:"100%"}}>
                            <Card size="small" style={{ backgroundColor: "#00A3FF" }}>
                                <Space direction="horizontal">
                                    Masz działający biznes lub stronę internetową na sprzedaż?{" "}
                                    <Link to="/assets">
                                        <Button type="primary">Wystaw swoją ofertę</Button>
                                    </Link>
                                </Space>
                            </Card>
                            <Assets limit={100} paginationLimit={100} />
                        </Space>
                    </Col>
                    <Col md={4}></Col>
                </Row>
            </Space>
        </>
    );
};

export default MarketplaceAssetsPage;
