import React, { useState, useEffect } from "react";
import {
    Space, Row, Col, Breadcrumb,
    Typography,
    Divider,
    Card,
    Button,
    Tag,
} from "antd";
import { Link, useParams } from "react-router-dom";
import {
    HomeOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet-async";
import { Freelancers } from "../Offer/FreelancersPage";
const { Title } = Typography;

const MarketplaceFreelancersPage = () => {
    const { tagFromUrl } = useParams(); // Fetch tag from URL
    return (
        <>
            <Helmet>
                <title>{tagFromUrl ? tagFromUrl : "Freelancerzy na Taskizy.com"}</title>
            </Helmet>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "15px 15px" }}>
                        <Space direction="vertical">

                            <Space direction="horizontal">

                                <img src="/img/svg/24.svg" style={{ width: "50px" }} />
                                <Title level={2}>
                                    Znajdź wykonawcę {tagFromUrl ? <>zadania związanego z "{tagFromUrl}"</> : ""}
                                </Title>

                            </Space>
                            <Space wrap={true}>
                                Popularne usługi:
                                <Link to={`/marketplace/freelancers/PHP`}>
                                    <Tag color="blue" style={{ padding: 5, fontSize: 14 }}>
                                        <u>#PHP</u>
                                    </Tag>
                                </Link>
                                <Link to={`/marketplace/freelancers/UX`}>
                                    <Tag style={{ padding: 5, fontSize: 14 }} color="blue">
                                        <u>#UX</u>
                                    </Tag>
                                </Link>
                                <Link to={`/marketplace/freelancers/WordPress`}>
                                    <Tag style={{ padding: 5, fontSize: 14 }} color="blue">
                                        <u>#WordPress</u>
                                    </Tag>
                                </Link>
                                <Link to={`/marketplace/freelancers/ReactJS`}>
                                    <Tag style={{ padding: 5, fontSize: 14 }} color="blue">
                                        <u>#ReactJS</u>
                                    </Tag>
                                </Link>
                                <Link to={`/marketplace/freelancers/JavaScript`}>
                                    <Tag style={{ padding: 5, fontSize: 14 }} color="blue">
                                        <u>#JavaScript</u>
                                    </Tag>
                                </Link>
                                <Link to={`/marketplace/freelancers/AWS`}>
                                    <Tag style={{ padding: 5, fontSize: 14 }} color="blue">
                                        <u>#AWS</u>
                                    </Tag>
                                </Link>
                                <Link to={`/marketplace/freelancers/AWS`}>
                                    <Tag style={{ padding: 5, fontSize: 14 }} color="blue">
                                        <u>#Android</u>
                                    </Tag>
                                </Link>
                            </Space>
                            <p>
                                Wynajęcie freelancera przez Taskizy.com pozwala na szybkie znalezienie specjalistów IT, którzy dopasowują się do indywidualnych potrzeb projektu. Dzięki platformie zyskujesz dostęp do weryfikowanych ekspertów, co zwiększa pewność jakości usług. Taskizy.com umożliwia prostą komunikację i pełną kontrolę nad projektem dzięki transparentności procesów. Dodatkowo, możesz skorzystać z elastycznych warunków współpracy, dopasowanych do budżetu i harmonogramu.
                            </p>
                            <Breadcrumb
                                items={[
                                    {
                                        href: "/",
                                        title: <HomeOutlined />,
                                    },
                                    {
                                        title: "Zamów usługę",
                                    },
                                ]}
                            />
                        </Space>
                    </Col>
                    <Col md={4} style={{ textAlign: "center" }}>

                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Divider></Divider>
                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "0px 15px" }}>

                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Card size="small" style={{ backgroundColor: "#00A3FF" }}>
                                <Space direction="horizontal">
                                    Jesteś freelancerem bez działalności gospodarczej lub prowadzisz już firmę i chcesz pozyskać nowych klientów?{" "}
                                    <Link to="/account/register">
                                        <Button type="primary">Dołącz do Taskizy</Button>
                                    </Link>
                                </Space>
                            </Card>
                            <Freelancers tagFromUrl={tagFromUrl} limit={100} paginationLimit={100} />
                        </Space>
                    </Col>
                    <Col md={4}></Col>
                </Row>
            </Space>
        </>
    );
};

export default MarketplaceFreelancersPage;
