import {
  Button,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Divider,
  Card,
  Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { RoughNotation } from "react-rough-notation";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import HelpBox from "./HelpBox";
const { Title } = Typography;

const JoinUsPage = () => {
  const [visible, setVisible] = useState(null);

  return (
    <>
      {" "}
      <Helmet>
        <title>Zacznij sprzedawać swoje usługi z taskizy.com</title>
      </Helmet>
      <Row justify="space-between" align="middle" gutter={12}>
        <Col md={4}></Col>
        <Col md={8} style={{ padding: "0px 15px" }}>
          <Space direction="vertical">
            <Title level={2}>
              Sprzedaż usług z pewnością wypłaty wynagrodzenia
            </Title>
          </Space>
        </Col>
        <Col md={8} style={{ textAlign: "center", padding: "15px" }}>
          <br />
          <img src="/img/svg/15.svg" alt="" style={{ width: "100%" }} />
        </Col>
        <Col md={4}></Col>
      </Row>
      <Row align={"top"}>
        <Col md={4}></Col>
        <Col md={8} style={{ padding: "0px 15px" }}>
          <Link to="/account/register">
            <Button size="large" type="primary">
              Utwórz profil za darmo
            </Button>
          </Link>
          <Space direction="vertical">
            <Title level={3}>
              Zawierając umowy na taskizy.com, zyskujesz pewność, że każda ze
              stron wywiąże się ze swoich zobowiązań
            </Title>
            <img
              src="/img/svg/17.svg"
              style={{ width: "100%", maxWidth: "330px" }}
            />
            <p>
              Środki zamawiającego są zabezpieczane od początku współpracy i
              wypłacane dopiero po zgodnym z umową wykonaniu pracy. Dzięki
              dostępowi do nowoczesnych narzędzi IT i przejrzystej komunikacji
              możesz realizować transakcje w bezpiecznym i w pełni
              transparentnym środowisku.
            </p>
            <Title level={3}>Jak zacząć oferować usługi na taskizy.com?</Title>
            <p>
              Dzięki platformie taskizy.com zyskasz dostęp do nowych klientów
              biznesowych, pewność terminowych płatności oraz pełną ochronę
              transakcji za pomocą systemu escrow.
            </p>
            <p>
              taskizy.com oferuje gotowy katalog usług, do którego łatwo
              przypiszesz swoją firmę po założeniu konta. Nie musisz tworzyć
              ofert ani cenników – zrobiliśmy to za Ciebie! Jednym kliknięciem
              aktywujesz usługę, a Twoja firma automatycznie znajdzie się na
              liście dostawców tej usługi.
            </p>
          </Space>
        </Col>
        <Col md={8} style={{ textAlign: "center", padding: "15px 15px" }}>
          <Space size={30} direction="vertical">
            <img src="/img/svg/21.svg" alt="" style={{ width: "100%" }} />
            <HelpBox></HelpBox>
            <Link to="/marketplace/product/dc5d1ae3-1496-4e9d-b2b0-8b1fc8e77731">
              Zobacz przykład oferty
            </Link>
          </Space>
        </Col>
        <Col md={4}></Col>
      </Row>
    </>
  );
};

export default JoinUsPage;
