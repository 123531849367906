import {
  Row,
  Col,
  Typography,
  Space,
  Spin,
  Divider,
  Breadcrumb,
  Card,
  Tag,
  Alert,
  Button,
} from "antd";
import React, { useState, useEffect } from "react";
import { HomeOutlined } from "@ant-design/icons";
import { api } from "../../config/api";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";
import { formatPolishCurrency } from "../../helpers/currency";
import { getAuthToken, isLogged } from "../../helpers/account";
import NewTransactionForm from "../Transaction/Forms/NewTransactionForm";
import AccountTags from "../Dashboard/Account/AccountTags";

const { Title } = Typography;

const MarketplaceAccountProfilePage = () => {
  const { accountUuid } = useParams(); // Capture the UUID from the route
  const [account, setAccount] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const response = await fetch(
          `${api.host}/accounts/${accountUuid}/public`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch account details");
        }
        const data = await response.json();
        setAccount(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching account data:", error);
        setLoading(false);
      }
    };

    fetchAccountData();
  }, [accountUuid]);

  // Fetch assigned products
  useEffect(() => {
    const fetchAssignedProducts = async () => {
      try {
        const apiEndpoint = isLogged()
          ? `${api.host}/accounts/products/${accountUuid}`
          : `${api.host}/accounts/products/${accountUuid}/public`;

        const response = await fetch(apiEndpoint, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            ...(isLogged() && { Authorization: `${getAuthToken()}` }), // Add Authorization header only if logged in
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch assigned products");
        }

        const data = await response.json();
        setProducts(data);
        setLoadingProducts(false);
      } catch (error) {
        console.error("Error fetching assigned products:", error);
        setLoadingProducts(false);
      }
    };

    fetchAssignedProducts();
  }, [accountUuid]);

  if (loading) {
    return (
      <Spin spinning={loading}>
        <div style={{ textAlign: "center", margin: "20px" }}>
          Ładuje profil użytkownika
        </div>
      </Spin>
    );
  }

  return (
    <>
      <Helmet>
        <title>{account?.company_name || account?.fullname || ""} - profil firmy</title>
      </Helmet>

      <Row justify="space-between" align="middle" style={{ background: "rgba(0, 0, 0, 0.05)", minHeight: "400px" }}>
        <Col md={4}></Col>
        <Col xs={24} md={16} style={{ textAlign: "center", padding: "15px" }}>
          <Space direction="vertical">
            <AvatarDisplay src={account?.photo} account={account} size={90} />
            <Title level={4}>{account?.fullname}</Title>
            <p style={{ marginTop: 0 }}>
              {account?.company_name || account?.fullname || ""}
            </p>
            {isLogged() ? (
              <>
                <NewTransactionForm
                  initiatorTransactionRoleId={{
                    value: 2,
                    label: "Nabywca",
                  }}
                  email={account.email}
                />
              </>
            ) : (
              <Link to="/transactions">
                <Button type="primary">Zleć zadanie</Button>
              </Link>
            )}

            <br />

            <Breadcrumb
              items={[
                {
                  href: "/",
                  title: <HomeOutlined />,
                },
                {
                  href: "/account/offer",
                  title: "Oferta firmy",
                },
                {
                  title: account?.company_name || account?.fullname,
                },
              ]}
            />
          </Space>
        </Col>

        <Col md={4}></Col>
      </Row>
      <br />
      <Row justify="space-between">
        <Col md={4}></Col>
        <Col md={16} style={{ padding: "0px 15px" }}>
          <Spin spinning={loading}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Title level={3}>
                Oferta {account?.company_name || account?.fullname}
              </Title>
              {account.description ? <> <p>
                {account.description}
              </p></> : <> <p>
                Wybierz {account?.company_name || account?.fullname}, skontaktuj się, aby sprawdzić dostępność, a następnie rozpocznij transakcję na platformie, ustalając indywidualne warunki. Orientacyjny czas realizacji i koszt to wartości zaproponowane przez taskizy.com, które pomogą Ci podjąć decyzję.
              </p></>}
              {account?.tags?.length > 0 && <AccountTags tags={account.tags} />}



              {products.map((product) => (
                <Card key={product.product.uuid}>
                  <Link to={`/marketplace/product/${product.product.uuid}`}>
                    <Title level={4}>{product.product.name}</Title>
                  </Link>
                  <Space direction="vertical">
                    <div>Orientacyjny czas realizacji: {product.product.delivery_days} dni</div>
                    <div>
                      Orientacyjna cena: <Tag color="gold-inverse">{formatPolishCurrency(product.product.price)}</Tag>
                    </div>
                    <div>
                      <Space>
                        {isLogged() ? (
                          <>
                            <NewTransactionForm
                              initiatorTransactionRoleId={{
                                value: 2,
                                label: "Nabywca",
                              }}
                              title={product.product.name}
                              email={product.account.email}
                            />
                          </>
                        ) : (
                          <Link to="/transactions"><Button type="primary">Zamów</Button></Link>
                        )}
                      </Space>
                    </div>
                  </Space>
                </Card>
              ))}
            </Space>
          </Spin>
        </Col>

        <Col md={4}></Col>
      </Row>
    </>
  );
};

export default MarketplaceAccountProfilePage;
