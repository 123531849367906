import React, { useState, useEffect } from "react";
import { Avatar, Typography, Space, Spin, Tooltip, Card, Tag, Button, Row, Col } from "antd";
import { api } from "../../config/api"; // Adjust the path to your API configuration
import { AreaChartOutlined, SmileTwoTone } from "@ant-design/icons";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";
import { RoughNotation } from "react-rough-notation";
import AccountTags from "../Dashboard/Account/AccountTags";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

const RecentProfilesList = ({ limit }) => {
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRecentAccounts = async () => {
            try {
                const response = await fetch(`${api.host}/accounts/new`);
                if (!response.ok) {
                    throw new Error("Failed to fetch recent accounts.");
                }
                const data = await response.json();
                setAccounts(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching recent accounts:", error);
                setLoading(false);
            }
        };

        fetchRecentAccounts();
    }, []);

    return (
        <Spin spinning={loading}>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Row gutter={12}>


                    {accounts.length > 0 ? (
                        accounts.map((account) => (
                            account.tags.length > 0 ? <>

                                <Col xs={12}>
                                    <Card size="small" key={account.uuid} style={{ display: "flex", alignItems: "center" }}>
                                        <Space direction="vertical">

                                            <Space direction="vertical" style={{ marginLeft: 16 }}>
                                                <Space wrap={true}>
                                                    <AvatarDisplay account={account} src={account.photo} size={80} />
                                                    <Link to={`/profile/${account.uuid}`}><Title level={5}>{account.fullname || "Anonimowy użytkownik"}</Title></Link>
                                                    {account.company_name && (
                                                        <Tag>{account.company_name.length > 14 ? `${account.company_name.substring(0, 14)}...` : account.company_name}</Tag>
                                                    )}
                                                </Space>
                                                <Space direction="vertical" wrap={true}>


                                                    <Link to={`/profile/${account.uuid}`}><Button type="primary">Zleć zadanie</Button></Link>
                                                    {account.description ? <> <p>
                                                        {account.description.length > 120 ? `${account.description.substring(0, 120)}...` : account.description}
                                                    </p></> : null}

                                                </Space>
                                            </Space>

                                            <Card size="small" bordered={false}>
                                                {account?.tags?.length > 0 && <AccountTags truncate={true} limit={3} tags={account.tags} />}
                                            </Card>
                                        </Space>
                                    </Card>
                                </Col>

                            </> : ""

                        ))
                    ) : (
                        <Text type="secondary">Brak nowo zarejestrowanych użytkowników.</Text>
                    )}
                </Row>
            </Space>
        </Spin>
    );
};

export default RecentProfilesList;
