import { Space, Descriptions, Spin, Row, Col, Typography } from "antd";
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { api } from "../../config/api";
import { formatPolishCurrency } from "../../helpers/currency";
import { Helmet } from "react-helmet-async";
const { Title } = Typography;

const PricingPage = () => {
  const [categoryPricingData, setCategoryPricingData] = useState([]);
  const [escrowFeeSettingsData, setEscrowFeeSettingsData] = useState([]);
  const [loadingCategoryPricing, setLoadingCategoryPricing] = useState(true);
  const [loadingEscrowFeePricing, setLoadingEscrowFeePricing] = useState(true);

  const categoryPricingColumns = [
    {
      title: "Nazwa kategorii",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      sorter: true,
      render: (name) => <>{name}</>,
    },
    {
      title: "Cena Netto",
      dataIndex: "escrow_fee",
      key: "escrow_fee",
      render: (escrow_fee) => formatPolishCurrency(escrow_fee),
    },
  ];

  useEffect(() => {
    if (loadingCategoryPricing === true) {
      // Your API endpoint URL goes here
      const apiUrl = `${api.host}/categories`; // Update with your API endpoint

      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setCategoryPricingData(data); // Update the state with the fetched data
          setLoadingCategoryPricing(false); // Set loading to false
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoadingCategoryPricing(false); // Set loading to false even on error
        });
    }
  }, [loadingCategoryPricing]);

  useEffect(() => {
    if (loadingEscrowFeePricing === true) {
      // Your API endpoint URL goes here
      const apiUrl = `${api.host}/escrow-fee-settings`; // Update with your API endpoint

      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setEscrowFeeSettingsData(data[0]); // Update the state with the fetched data
          setLoadingEscrowFeePricing(false); // Set loading to false
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoadingEscrowFeePricing(false); // Set loading to false even on error
        });
    }
  }, [loadingEscrowFeePricing]);

  return (
    <>
      {" "}
      <Helmet>
        <title>taskizy.com - Cennik</title>
      </Helmet>
      <Space size={30} direction="vertical" style={{ width: "100%" }}>
        <Row>
          <Col md={4}></Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <Space direction="vertical">
              <Title level={2}>Niewielka prowizja za pewność realizacji zlecenia</Title>

              <p>
                Pobieramy opłatę za obsługę transakcji
                oraz procent od wartości przedmiotu umowy.
              </p>
            </Space>
          </Col>
          <Col xs={24} md={8} style={{ textAlign: "center" }}>
            <br />
            <center>
            <img src="/img/svg/2.svg" alt="" />
            </center>
            <br/>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row justify="space-between">
          <Col md={4}></Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <Space direction="vertical">
              <p>
                Chronimy Twoje finanse, minimalizując ryzyko utraty płynności i
                zabezpieczając pieniądze przed oszustwami oraz nieuczciwymi
                kontrahentami. W razie niewywiązania się drugiej strony z umowy,
                Twoje środki pozostają z nami bezpieczne.
              </p>
            </Space>
          </Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <Space size={30} direction="vertical">
              <Spin spinning={loadingEscrowFeePricing}>
                <Descriptions
                  layout="vertical"
                  bordered
                  items={[
                    {
                      key: "1",
                      label: "Koszt obsługi transakcji",
                      children: <>9, 90 brutto</>,
                    },
                  ]}
                />
                <br />
                <Descriptions
                  layout="vertical"
                  bordered
                  items={[
                    {
                      key: "1",
                      label: "Prowizja od wartości transakcji",
                      children: (
                        <>{escrowFeeSettingsData.percentage * 100}% brutto</>
                      ),
                    },
                  ]}
                />
                <br />
                <Descriptions
                  layout="vertical"
                  bordered
                  items={[
                    {
                      key: "2",
                      label:
                        "Przekazanie konfliktu do rozwiązania przez negocjatora",
                      children: (
                        <>
                          {formatPolishCurrency(
                            escrowFeeSettingsData.conflict_resolution_cost
                          )}
                        </>
                      ),
                    },
                  ]}
                />
              </Spin>
            </Space>
          </Col>
          <Col md={4}></Col>
        </Row>
      </Space>
    
    </>
  );
};

export default PricingPage;
