import React, { useState } from "react";
import { Tag, Space, Button } from "antd";
import { Link } from "react-router-dom";

const AccountTags = ({ tags, limit, truncate }) => {
  const [showMore, setShowMore] = useState(false);

  if (!tags || tags.length === 0) {
    return null; // Return nothing if there are no tags
  }

  // Apply the limit if provided, otherwise show all tags
  const displayedTags = showMore ? tags : tags.slice(0, limit);

  // Function to truncate tag name if required
  const gettruncatemedTagName = (tagName) => {
    if (truncate && tagName.length > 14) {
      return `${tagName.substring(0, 14)}...`; // truncate and add ellipses
    }
    return tagName;
  };

  return (
    <div>
      <Space wrap={true}>
        {displayedTags.map((tag) => (
          <Link to={`/marketplace/freelancers/${tag.name}`} key={tag.uuid}>
            <Tag style={{padding:5, fontSize:14}} color="blue"><u>#{gettruncatemedTagName(tag.name)}</u></Tag>
          </Link>
        ))}
      </Space>

      {/* Show "i X więcej" text if there are more tags */}
      {tags.length > limit && !showMore && (
        <Button
          type="link"
          onClick={() => setShowMore(true)}
          style={{ padding: 0 }}
        >
          i {tags.length - limit} więcej
        </Button>
      )}
    </div>
  );
};

export default AccountTags;
