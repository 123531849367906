import React, { useState, useEffect } from "react";
import {
  Space,
  Button,
  Modal,
  Input,
  message,
  Spin,
  DatePicker,
  InputNumber,
  Checkbox,
  Divider,
  Alert,
} from "antd";
import dayjs from "dayjs";
import { isLogged, getAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";
import { useDispatch } from "react-redux";

import { ThunderboltTwoTone } from "@ant-design/icons";
import { isEmailValid, isValidUrl } from "../../../helpers/validators";

const { TextArea } = Input;

const EditInquiryForm = ({ inquiryUuid, onInquiryUpdated }) => {
  const [editInquiryModalOpen, setEditInquiryModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);
  const [correctedMessage, setCorrectedMessage] = useState("");
  const [showCorrectedMessage, setShowCorrectedMessage] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    budget: "",
    message: "",
    valid_to: null,
    delivery_days: "",
    is_active: false,
    public_email: "",
    public_url: "",
    is_escrow_enabled: true
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (editInquiryModalOpen && inquiryUuid) {
      setLoading(true);
      const apiUrl = `${api.host}/inquiries/${inquiryUuid}`;
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch inquiry data");
          }
          return response.json();
        })
        .then((data) => {
          setFormData({
            name: data.name,
            budget: data.budget,
            message: data.message,
            valid_to: data.valid_to ? dayjs(data.valid_to) : null,
            delivery_days: data.delivery_days,
            is_active: data.is_active,
            public_email: data.public_email,
            public_url: data.public_url,
            is_escrow_enabled: data.is_escrow_enabled,
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching inquiry data:", error);
          message.error("Nie udało się pobrać danych zapytania.");
          setLoading(false);
        });
    }
  }, [editInquiryModalOpen, inquiryUuid]);

  const handleEditInquiry = () => {
    if (validateFormData()) {
      setLoading(true);

      const apiData = {
        name: formData.name,
        budget: formData.budget,
        message: formData.message,
        valid_to: formData.valid_to
          ? formData.valid_to.format("YYYY-MM-DD HH:mm:ss")
          : "",
        delivery_days: formData.delivery_days,
        is_active: formData.is_active,
        public_email: formData.public_email,
        public_url: formData.public_url,
        is_escrow_enabled: formData.is_escrow_enabled
      };

      const apiUrl = `${api.host}/inquiries/${inquiryUuid}`;

      if (isLogged()) {
        fetch(apiUrl, {
          method: "PUT",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
          body: JSON.stringify(apiData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to create inquiry");
            }
            return response.json();
          })
          .then(() => {
            setLoading(false);
            dispatch({
              type: "inquiryDataUpdated",
              payload: {
                inquiryDataChanged: new Date().getSeconds(),
              },
            });
            message.success("Zapytanie zostało pomyślnie utworzone.");
            setEditInquiryModalOpen(false);
            setFormData({
              name: "",
              budget: "",
              message: "",
              valid_to: null,
              delivery_days: "",
              is_active: false,
              public_email: "",
              public_url: "",
              is_escrow_enabled: true
            });
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error while creating inquiry:", error);
            message.error("Wystąpił błąd podczas tworzenia zapytania.");
          });
      }
    }
  };

  const validateFormData = () => {
    if (
      !formData.name ||
      !formData.budget ||
      !formData.message ||
      !formData.valid_to ||
      !formData.delivery_days
    ) {
      message.error("Wszystkie pola są wymagane.");
      return false;
    }
    if (isNaN(parseFloat(formData.budget))) {
      message.error("Budżet musi być liczbą.");
      return false;
    }
    if (isNaN(parseInt(formData.delivery_days))) {
      message.error("Czas dostawy musi być liczbą.");
      return false;
    }
    if (formData.public_email && !isEmailValid(formData.public_email)) {
      message.error("Podano nieprawidłowy adres e-mail.");
      return false;
    }
    if (formData.public_url && !isValidUrl(formData.public_url)) {
      message.error("Podano nieprawidłowy URL.");
      return false;
    }
    return true;
  };

  const handleNewInquiry = () => {
    setEditInquiryModalOpen(true);
  };

  const handleCancelNewInquiry = () => {
    setEditInquiryModalOpen(false);
    setCorrectedMessage("");
    setShowCorrectedMessage(false);
  };

  const handleAICorrection = async () => {
    if (!formData.message) {
      message.error("Treść zapytania nie może być pusta.");
      return;
    }

    setAiLoading(true);
    const apiUrl = `${api.host}/chatbot`;

    try {
      const message = `Treść zlecenia: ${formData.message},
      \n\n
      tytuł zlecenia: ${formData.name}
      \n\n
      data wykonania do: ${formData.valid_to}      
      \n\n
      budżet na realizację zadania: ${formData.budget}
      \n\n
      `;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        },
        body: JSON.stringify({ message: message }),
      });

      if (response.ok) {
        const data = await response.text();
        console.log(data);
        setCorrectedMessage(
          data
            .replace(/\\n/g, "\n")
            .replace(/[^a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ.\-,\s\n]/g, "")
        );
        setShowCorrectedMessage(true);
      } else {
        throw new Error("Failed to fetch AI correction");
      }
    } catch (error) {
      console.error("Error while calling AI correction API:", error);
      message.error("Wystąpił błąd podczas poprawiania ogłoszenia.");
    } finally {
      setAiLoading(false);
      message.success("Ogłoszenie zostało zredagowane przez AI.");
    }
  };

  const handleUseCorrectedMessage = () => {
    setFormData({ ...formData, message: correctedMessage });
    setCorrectedMessage("");
    setShowCorrectedMessage(false);
    message.success("Zastosowano poprawioną treść AI.");
  };

  return (
    <>
      <Spin spinning={loading}>
        <Button onClick={handleNewInquiry} type="primary">
          Edytuj zapytanie
        </Button>

        <Modal
          title="Edytuj zapytanie"
          open={editInquiryModalOpen}
          onOk={handleEditInquiry}
          onCancel={handleCancelNewInquiry}
          okText="Zapisz"
          cancelText="Anuluj"
          width={800}
        >
          <Space size={15} direction="vertical" style={{ width: "100%" }}>
            <Input
              placeholder="Nazwa zapytania"
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              value={formData.name}
            />

            <Space>
              <InputNumber
                placeholder="Budżet"
                min={1}
                onChange={(value) =>
                  setFormData({ ...formData, budget: value })
                }
                value={formData.budget}
              />
              <DatePicker
                format="YYYY-MM-DD"
                placeholder="Ważne do (YYYY-MM-DD)"
                onChange={(date) =>
                  setFormData({ ...formData, valid_to: date })
                }
                value={formData.valid_to}
              />
            </Space>
            <InputNumber
              placeholder="Czas dostawy (dni)"
              min={1}
              onChange={(value) =>
                setFormData({ ...formData, delivery_days: value })
              }
              value={formData.delivery_days}
            />
            <TextArea
              placeholder="Treść zapytania ofertowego"
              rows={4}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
              value={formData.message}
            />
            <Button
              type="default"
              onClick={handleAICorrection}
              loading={aiLoading}
            >
              <ThunderboltTwoTone /> Popraw treść z TaskizyAI
            </Button>
            {showCorrectedMessage && (
              <Space direction="vertical" style={{ width: "100%" }}>
                <TextArea
                  placeholder="Zredagowane ogłoszenie"
                  rows={4}
                  value={correctedMessage}
                  readOnly
                />
                <Button type="default" onClick={handleUseCorrectedMessage}>
                  Użyj poprawionej treści AI
                </Button>
              </Space>
            )}
            <Divider></Divider>
            <Input
              placeholder="E-mail do kontaktu"
              onChange={(e) =>
                setFormData({ ...formData, public_email: e.target.value })
              }
              value={formData.public_email}
            />
            <Input
              placeholder="Url z dodatkowymi informacjami"
              onChange={(e) =>
                setFormData({ ...formData, public_url: e.target.value })
              }
              value={formData.public_url}
            />  
            <Alert message="
Wyłączenie Escrow spowoduje, że system Taskizy przestanie umożliwiać zgłaszanie chęci wykonania zlecenia. W zamian zostaną udostępnione adres e-mail oraz URL z dodatkowymi informacjami do bezpośredniego kontaktu z Tobą.">

            </Alert>
            <Checkbox
              checked={formData.is_escrow_enabled}
              onChange={(e) =>
                setFormData({ ...formData, is_escrow_enabled: e.target.checked })
              }
            >
              Realizacja z Escrow Taskizy
            </Checkbox>
            <Divider></Divider>
            <Checkbox
              checked={formData.is_active}
              onChange={(e) =>
                setFormData({ ...formData, is_active: e.target.checked })
              }
            >
              Widoczne publicznie
            </Checkbox>
          </Space>
        </Modal>
      </Spin>
    </>
  );
};

export default EditInquiryForm;
