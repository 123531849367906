import React, { useState, useEffect } from "react";
import { Alert, Button, Space, Spin } from "antd";
import { formatPolishCurrency } from "../../../helpers/currency";
import {
  getLoggedAccountTransactionRoleId,
  getAuthToken,
} from "../../../helpers/account";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { api } from "../../../config/api";
import CheckoutForm from "../Payment/CheckoutForm";
import { Link } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_live_51PFiA9P9o5jlolOrdoEQa8hSc5MKcwy9pRt2ae5zBnFD05yRr1i5VBijWKuPgOSc7B14EV6tXNyxwXWdJCrOwD1e00rmYzOloq"
);

const LoadEscrowAction = (props) => {
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [accountantData, setAccountantData] = useState(null);

  useEffect(() => {
    // Fetch accountant details when the component loads
    const fetchAccountantData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${api.host}/accounts/${sessionStorage.getItem("uuid")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${getAuthToken()}`,
            },
          }
        );
        const data = await response.json();
        setAccountantData(data.accountant); // Assuming accountant details are part of the response
      } catch (error) {
        console.error("Failed to fetch accountant data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountantData();
  }, []);

  const handlePaymentIntent = () => {
    setLoading(true);
    fetch(`${api.host}/payments/intent/${props.transaction.uuid}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch((error) => console.error("Error creating payment intent:", error))
      .finally(() => setLoading(false));
  };

  const canBeRendered =
    props.transaction &&
    getLoggedAccountTransactionRoleId(props.transaction) === 2 &&
    props.transaction.agreement_accepted_by_initiator &&
    props.transaction.agreement_accepted_by_counterparty &&
    !props.transaction.escrow_received;

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    canBeRendered && (
      <Space direction="vertical">
        {loading && <Spin />}
        {accountantData && (
          <Alert
            type="warning"
            message={
              <>
                Wpłać depozyt w wysokości{" "}
                {formatPolishCurrency(props.transaction.escrow_amount_total)}
              </>
            }
            description={
              <>
                Proszę wpłacić przelewem depozyt, aby kontynuować transakcję.
                Szczegóły rozliczeń dostępne w zakładce Rozliczenia.
                <p>
                  <strong>Nazwa odbiorcy:</strong> {accountantData.name}
                </p>
                <p>
                  <strong>Numer konta:</strong> {accountantData.bank_number}
                </p>
                <p>
                  <strong>Tytuł przelewu:</strong> {props.transaction.uuid}
                </p>
              </>
            }
          />
        )}
        {!accountantData && (
          <Alert
            type="warning"
            message={
              <>
                Wpłać depozyt w wysokości{" "}
                {formatPolishCurrency(props.transaction.escrow_amount_total)}
              </>
            }
            description={
              <>
                <Link to="/account/data">Przejdź do ustawień konta</Link> i wybierz Partnera Rozliczającego aby
                dokonać wpłaty na konto.
              </>
            }
          />
        )}

        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}
      </Space>
    )
  );
};

export default LoadEscrowAction;
