import React, { useState, useEffect } from "react";
import { Row, Col, Divider, Space, Breadcrumb, Typography, Tag } from "antd";
import LoginPage from "../Login/LoginPage";
import { isLogged } from "../../helpers/account";
import { Catalog } from "../Offer/CatalogPage";
import {
    HomeOutlined,
    LikeTwoTone,
    SafetyCertificateTwoTone
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { RoughNotation } from "react-rough-notation";
import PromotedProducts from "../Offer/PromotedProducts";
const { Title, Text } = Typography;

const OffersPage = () => {
    if (isLogged()) {
        return (
            <>
                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "15px 15px" }}>
                        
                        <Space direction="vertical">
                            <Title level={2}>
                                Zamów usługę z pewnością wykonania
                            </Title>
                            <p>
                            Na taskizy.com wykonawcy dołączają do gotowych ofert usług przygotowanych przez platformę, zamiast tworzyć własne od podstaw. Dzięki temu zamawiający mogą szybko porównać różne propozycje, a wykonawcy łatwiej znajdują klientów.
                            </p>
                            <Breadcrumb
                                items={[
                                    {
                                        href: "/",
                                        title: <HomeOutlined />,
                                    },
                                    {
                                        title: "Zamów usługę z pewnością wykonania",
                                    },
                                ]}
                            />
                        </Space>
                    </Col>
                    <Col md={4} style={{ textAlign: "center" }}>

                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Divider></Divider>
                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "0px 15px" }}>
                    <Space direction="vertical">
                            <Title
                                level={2}
                                style={{ color: "rgba(255, 136, 0, 1)", fontWeight: 100 }}
                            >
                                <RoughNotation multiline={true} type="underline" show={true}>
                                    Wybrana oferta z katalogu usług
                                </RoughNotation>
                            </Title>
                            <Title level={3}>
                                <LikeTwoTone /> Sklep dla kwiaciarni w 14 dni{" "}
                                <Tag color="cyan" style={{ fontWeight: 400 }}>
                                    Oferta czasowa
                                </Tag>
                            </Title>
                            <Link to="/marketplace/product/f55620d8-2a65-4b39-87f4-f8772a6c0ca5">
                                <img
                                    src="/img/svg/flowers.svg"
                                    alt=""
                                    style={{ width: "100%" }}
                                />
                            </Link>
                        </Space>
                        <Divider></Divider>
                        <PromotedProducts />
                        <br/>
                        <Catalog />
                    </Col>
                    <Col md={4}></Col>
                </Row>
            </>
        );
    } else {
        return <LoginPage />;
    }
};

export default OffersPage;
