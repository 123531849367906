import React, { useState } from "react";
import { Alert, Button, Space, message, Drawer, Card, Radio } from "antd";

import { api } from "../../../config/api";
import {
  getAuthToken,
  isTransactionInitiatedByLoggedAccount,
} from "../../../helpers/account";
import { useDispatch } from "react-redux";

import {
  requiresConflictResolutionAcceptanceByInitiator,
  requiresConflictResolutionAcceptanceByCounterparty,
} from "../../../helpers/actions";

const ResolveConflictAction = (props) => {
  const [loading, setLoading] = useState(false);
  const [isResolutionRefused, setIsResolutionRefused] = useState(null);
  const [conflictResolution, setConflictResolution] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  if (isResolutionRefused === null) {
    if (isTransactionInitiatedByLoggedAccount(props.transaction) === true) {
      setIsResolutionRefused(
        props.transaction.conflict_resolution_accepted_by_initiator === false
      );
    } else {
      setIsResolutionRefused(
        props.transaction.conflict_resolution_accepted_by_counterparty === false
      );
    }
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  let canBeRendered = false;

  if (
    requiresConflictResolutionAcceptanceByInitiator(props.transaction) ||
    requiresConflictResolutionAcceptanceByCounterparty(props.transaction)
  ) {
    canBeRendered = true;
  }

  const onChange = (e) => {
    setConflictResolution(e.target.value);
  };

  const handleAcceptProposal = () => {
    if (props.transaction.transaction_conflict_resolution_id === null) {
      messageApi.open({
        type: "error",
        content:
          "Transakcja nie posiada zasugerowanego rozwiązania konfliktu, być może została przed chwilą zaktualizowana przez stronę umowy.",
      });
    }

    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/transactions/${props.transaction.uuid}`;

    let jsonData = {};
    if (isTransactionInitiatedByLoggedAccount(props.transaction)) {
      jsonData = {
        conflict_resolution_accepted_by_initiator: true,
        transaction_conflict_resolution_id:
          props.transaction.transaction_conflict_resolution_id,
      };
    } else {
      jsonData = {
        conflict_resolution_accepted_by_counterparty: true,
        transaction_conflict_resolution_id:
          props.transaction.transaction_conflict_resolution_id,
      };
    }

    // Fetch data from the API
    fetch(apiUrl, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData), // Convert JSON object to a string
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        messageApi.open({
          type: "success",
          content: "Informacja o otrzymaniu przedmiotu umowy została zapisana.",
        });
        setTimeout(() => {
          // Additional logic to be executed after a delay (2 seconds in this case)
          dispatch({
            type: "transactionDataUpdated",
            payload: {
              transactionDataChanged: new Date().getSeconds(),
            },
          });
          setLoading(false);
        }, 2000); // Delay for 2 seconds
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Wystąpiły błedy podczas zapisu umowy.",
        });
        setLoading(false); // Set loading to false even on error
      });
  };

  const handleRejectProposal = () => {
    setIsResolutionRefused(true);
    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/transactions/${props.transaction.uuid}`;
    let jsonData = {};
    if (isTransactionInitiatedByLoggedAccount(props.transaction)) {
      jsonData = {
        conflict_resolution_accepted_by_initiator: false,
        transaction_conflict_resolution_id:
          props.transaction.transaction_conflict_resolution_id,
      };
    } else {
      jsonData = {
        conflict_resolution_accepted_by_counterparty: false,
        transaction_conflict_resolution_id:
          props.transaction.transaction_conflict_resolution_id,
      };
    }

    // Fetch data from the API
    fetch(apiUrl, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData), // Convert JSON object to a string
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        messageApi.open({
          type: "success",
          content: "Informacja została zapisana.",
        });
        setTimeout(() => {
          // Additional logic to be executed after a delay (2 seconds in this case)
          dispatch({
            type: "transactionDataUpdated",
            payload: {
              transactionDataChanged: new Date().getSeconds(),
            },
          });
          setLoading(false);
        }, 2000); // Delay for 2 seconds
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Wystąpiły błedy podczas zapisu umowy.",
        });
        setLoading(false); // Set loading to false even on error
      });
  };

  const handleProposal = () => {
    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/transactions/${props.transaction.uuid}`;
    let jsonData = {};
    if (isTransactionInitiatedByLoggedAccount(props.transaction)) {
      jsonData = {
        conflict_resolution_accepted_by_initiator: true,
        transaction_conflict_resolution_id: conflictResolution,
      };
    } else {
      jsonData = {
        conflict_resolution_accepted_by_counterparty: true,
        transaction_conflict_resolution_id: conflictResolution,
      };
    }

    // Fetch data from the API
    // Wysyla propozycje
    fetch(apiUrl, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData), // Convert JSON object to a string
    })
      .then((response) => response.json())
      .then((data) => {
        // Akceptuje wyslana propozycje (2 kroki wymagane przez proces transakcji)
        fetch(apiUrl, {
          method: "PUT",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
          body: JSON.stringify(jsonData), // Convert JSON object to a string
        })
          .then((response) => response.json())
          .then((data) => {
            // Handle the response data
            messageApi.open({
              type: "success",
              content: "Informacja została zapisana.",
            });
            setTimeout(() => {
              // Additional logic to be executed after a delay (2 seconds in this case)
              dispatch({
                type: "transactionDataUpdated",
                payload: {
                  transactionDataChanged: new Date().getSeconds(),
                },
              });
              setLoading(false);
            }, 2000); // Delay for 2 seconds
          })
          .catch((error) => {
            messageApi.open({
              type: "error",
              content: "Wystąpiły błedy podczas zapisu umowy.",
            });
            setLoading(false); // Set loading to false even on error
          });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Wystąpiły błedy podczas zapisu umowy.",
        });
        setLoading(false); // Set loading to false even on error
      });
  };

  if (canBeRendered === true) {
    return (
      <>
        {contextHolder}
        <Drawer
          title="Zaproponuj sposób rozwiązania konfliktu"
          placement="right"
          onClose={onClose}
          open={open}
        >
          <Space direction="vertical">
            {props.transaction.transaction_conflict_resolution_id !== null ? (
              <>
                <Card title="Propozycja rozwiązania problemu">
                  <Space direction="vertical">
                    <Alert
                      type="info"
                      message="To jest propozycja zgłoszona przez stronę konfliktu. Jeśli się z nią nie zgadzasz, sugerujemy rozpoczęcie negocjacji poprzez kontakt z tą stroną."
                    ></Alert>
                    {props.transaction.transaction_conflict_resolution.name}

                    {isTransactionInitiatedByLoggedAccount(
                      props.transaction
                    ) === true &&
                    props.transaction
                      .conflict_resolution_accepted_by_initiator === false ? (
                      <>
                        <Alert
                          type="warning"
                          message="Odrzuciłeś propozycję"
                        ></Alert>
                      </>
                    ) : (
                      ""
                    )}

                    {isTransactionInitiatedByLoggedAccount(
                      props.transaction
                    ) === false &&
                    props.transaction
                      .conflict_resolution_accepted_by_counterparty ===
                      false ? (
                      <>
                        <Alert
                          type="warning"
                          message="Odrzuciłeś propozycję"
                        ></Alert>
                      </>
                    ) : (
                      ""
                    )}

                    {isTransactionInitiatedByLoggedAccount(
                      props.transaction
                    ) === true &&
                    props.transaction
                      .conflict_resolution_accepted_by_initiator === null ? (
                      <>
                        <Space>
                          <Button
                            size="middle"
                            type="primary"
                            danger
                            ghost
                            onClick={handleAcceptProposal}
                            loading={loading}
                          >
                            Zgadzam się
                          </Button>
                          <Button
                            size="middle"
                            type="primary"
                            ghost
                            onClick={handleRejectProposal}
                            loading={loading}
                          >
                            Odrzucam
                          </Button>
                        </Space>
                      </>
                    ) : (
                      ""
                    )}

                    {isTransactionInitiatedByLoggedAccount(
                      props.transaction
                    ) === false &&
                    props.transaction
                      .conflict_resolution_accepted_by_counterparty === null ? (
                      <>
                        <Space>
                          <Button
                            size="middle"
                            type="primary"
                            danger
                            ghost
                            onClick={handleAcceptProposal}
                            loading={loading}
                          >
                            Zgadzam się
                          </Button>
                          <Button
                            size="middle"
                            type="primary"
                            ghost
                            onClick={handleRejectProposal}
                            loading={loading}
                          >
                            Odrzucam
                          </Button>
                        </Space>
                      </>
                    ) : (
                      ""
                    )}
                  </Space>
                </Card>
              </>
            ) : (
              <></>
            )}

            {isResolutionRefused === true ||
            (props.transaction.transaction_conflict_resolution_id === null &&
              props.transaction.transaction_mode_id === 5) ? (
              <>
                {" "}
                <Card title="Twoja propozycja rozwiązania">
                  <Space direction="vertical">
                    <Radio.Group onChange={onChange} value={conflictResolution}>
                      <Space direction="vertical">
                        <Radio
                          disabled={
                            props.transaction
                              .transaction_conflict_resolution_id === 1
                              ? true
                              : false
                          }
                          value={1}
                        >
                          Kontynuacja transakcji
                        </Radio>
                        <Radio
                          disabled={
                            props.transaction
                              .transaction_conflict_resolution_id === 3
                              ? true
                              : false
                          }
                          value={3}
                        >
                          Zwrot usługi/towaru i zakończenie transakcji
                        </Radio>
                      </Space>
                    </Radio.Group>

                    <Button
                      size="middle"
                      type="primary"
                      ghost
                      onClick={handleProposal}
                      loading={loading}
                    >
                      Złóż swoją propozycję
                    </Button>
                  </Space>
                </Card>
              </>
            ) : (
              ""
            )}

            {isResolutionRefused === true ||
            (props.transaction.transaction_conflict_resolution_id === null &&
              props.transaction.transaction_mode_id === 2) ? (
              <>
                {" "}
                <Card title="Twoja propozycja rozwiązania">
                  <Space direction="vertical">
                    <Radio.Group onChange={onChange} value={conflictResolution}>
                      <Space direction="vertical">
                        <Radio
                          disabled={
                            props.transaction
                              .transaction_conflict_resolution_id === 1
                              ? true
                              : false
                          }
                          value={1}
                        >
                          Kontynuacja transakcji
                        </Radio>
                        <Radio
                          disabled={
                            props.transaction
                              .transaction_conflict_resolution_id === 2
                              ? true
                              : false
                          }
                          value={2}
                        >
                          Zakończenie transakcji i zwrot środków
                        </Radio>
                      </Space>
                    </Radio.Group>

                    <Button
                      size="middle"
                      type="primary"
                      ghost
                      onClick={handleProposal}
                      loading={loading}
                    >
                      Złóż swoją propozycję
                    </Button>
                  </Space>
                </Card>
              </>
            ) : (
              ""
            )}

            {isResolutionRefused === true ||
            (props.transaction.transaction_conflict_resolution_id === null &&
              props.transaction.transaction_mode_id === 12) ? (
              <>
                {" "}
                <Card title="Twoja propozycja rozwiązania">
                  <Space direction="vertical">
                    <Radio.Group onChange={onChange} value={conflictResolution}>
                      <Space direction="vertical">
                        <Radio
                          disabled={
                            props.transaction
                              .transaction_conflict_resolution_id === 1
                              ? true
                              : false
                          }
                          value={1}
                        >
                          Kontynuacja transakcji
                        </Radio>
                        <Radio
                          disabled={
                            props.transaction
                              .transaction_conflict_resolution_id === 4
                              ? true
                              : false
                          }
                          value={4}
                        >
                          Przerwanie i przekazanie do negocjatora
                        </Radio>
                      </Space>
                    </Radio.Group>

                    <Button
                      size="middle"
                      type="primary"
                      ghost
                      onClick={handleProposal}
                      loading={loading}
                    >
                      Złóż swoją propozycję
                    </Button>
                  </Space>
                </Card>
              </>
            ) : (
              ""
            )}
          </Space>
        </Drawer>
        <Alert
          message="Wskaż rozwiązanie konfliktu"
          description="Transakcja znajduje się w fazie konfliktu, w której strony umowy mają 14 dni od daty rozpoczęcia konfliktu, aby osiągnąć porozumienie w sprawie rozwiązania systemowego."
          type="warning"
          action={
            <Space direction="vertical">
              <Button type="primary" onClick={showDrawer} loading={loading}>
                Wskaz rozwiązanie
              </Button>
            </Space>
          }
        />
      </>
    );
  }
};

export default ResolveConflictAction;
