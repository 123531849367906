import {
    Typography,
    Space,
    Card,
    Spin,
    Tag,
    Button,
    Select,
  } from "antd";
  import React, { useState, useEffect } from "react";
  import { Link } from "react-router-dom";
  import { api } from "../../config/api";
  import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";
  import AccountTags from "../Dashboard/Account/AccountTags";
  
  const { Title, Text } = Typography;
  const { Option } = Select;
  
  export const Freelancers = ({ tagFromUrl, limit }) => {
    const [accounts, setAccounts] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedTag, setSelectedTag] = useState(tagFromUrl || null);
  
    useEffect(() => {
      const fetchFreelancers = async () => {
        try {
          const response = await fetch(`${api.host}/accounts/new`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          });
          if (!response.ok) throw new Error("Failed to fetch freelancers.");
          const data = await response.json();
          const limitedAccounts = limit ? data.slice(0, limit) : data;
          setAccounts(limitedAccounts);
  
          // Pre-filter accounts if a tag is passed via props
          if (tagFromUrl) {
            filterByTag(tagFromUrl, limitedAccounts);
          } else {
            setFilteredAccounts(limitedAccounts);
          }
          setLoading(false);
        } catch (error) {
          console.error("Error fetching freelancers:", error);
          setLoading(false);
        }
      };
  
      const fetchTags = async () => {
        try {
          const response = await fetch(`${api.host}/tags`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          });
          if (!response.ok) throw new Error("Failed to fetch tags.");
          const data = await response.json();
          setTags(data);
        } catch (error) {
          console.error("Error fetching tags:", error);
        }
      };
  
      fetchFreelancers();
      fetchTags();
    }, [limit, tagFromUrl]);
  
    useEffect(() => {
      // Dynamically update the filter when tagFromUrl changes
      if (tagFromUrl) {
        setSelectedTag(tagFromUrl);
        filterByTag(tagFromUrl);
      }
    }, [tagFromUrl, accounts]);
  
    const handleTagChange = (tag) => {
      setSelectedTag(tag);
      filterByTag(tag);
    };
  
    const filterByTag = (tagUuidOrName, accountsList = accounts) => {
        if (!tagUuidOrName) {
          setFilteredAccounts(accountsList); // Show all accounts if no tag is selected
          return;
        }
      
        const filtered = accountsList.filter((account) =>
          account.tags.some(
            (t) =>
              t.uuid === tagUuidOrName || t.name.toLowerCase() === tagUuidOrName.toLowerCase()
          )
        );
      
        setFilteredAccounts(filtered);
      };
      
  
    const handleSearch = (searchText) => {
      const matchingTags = tags.filter((tag) =>
        tag.name.toLowerCase().includes(searchText.toLowerCase())
      );
  
      if (matchingTags.length > 0) {
        const matchingTagName = matchingTags[0].name;
        setSelectedTag(matchingTagName);
        filterByTag(matchingTagName);
      }
    };
  
    return (
      <Spin spinning={loading}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Select
            style={{ width: 300, marginBottom: 16 }}
            placeholder="Filtruj po tagu"
            onChange={handleTagChange}
            onSearch={handleSearch}
            allowClear
            showSearch
            value={selectedTag}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {tags.map((tag) => (
              <Option key={tag.uuid} value={tag.uuid}>
                {tag.name}
              </Option>
            ))}
          </Select>
  
          {filteredAccounts.length > 0 ? (
            filteredAccounts.map((account) => (
              <Card
                key={account.uuid}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Space direction="horizontal" style={{ marginLeft: 16 }}>
                    <AvatarDisplay
                      account={account}
                      src={account.photo}
                      size={48}
                    />
                    <Space direction="vertical">
                      <Link to={`/profile/${account.uuid}`}>
                        <Title level={5}>
                          {account.fullname || "Anonimowy użytkownik"}
                        </Title>
                      </Link>
                      {account.company_name && <Tag>{account.company_name}</Tag>}
                    </Space>
                    <Link to={`/profile/${account.uuid}`}>
                      <Button type="primary">Zleć zadanie</Button>
                    </Link>
                  </Space>
                  {account.description ? <> <p>
                                                        {account.description}
                                                    </p></> : null}
                  <Card size="small" bordered={false}>
                    {account.tags.length > 0 && <AccountTags tags={account.tags} />}
                  </Card>
                </Space>
              </Card>
            ))
          ) : (
            <Text type="secondary">
              Brak zarejestrowanych użytkowników spełniających kryteria.
            </Text>
          )}
        </Space>
      </Spin>
    );
  };
  