import {
  Button,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Divider,
  Card,
  Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { RoughNotation } from "react-rough-notation";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
const { Title } = Typography;

const AboutPage = () => {
  const [visible, setVisible] = useState(null);

  return (
    <>
      {" "}
      <Helmet>
        <title>taskizy.com - bezpieczne transakcje online</title>
      </Helmet>
      <Row
        justify="space-between"
        align="middle"
        gutter={12}
      
      >
        <Col md={4}></Col>
        <Col md={8} style={{ padding: "30px 30px" }}>
          <Space direction="vertical">
            <Title level={2}>
              Mamy tylko jeden cel - zapewnić{" "}
              <RoughNotation
                multiline={true}
                color="#F4E98C"
                type="highlight"
                show={true}
              >
                bezpieczne
              </RoughNotation>{" "}
              transakcje pomiędzy kontrahentami{" "}
            </Title>

            <p>
              Minimalizujemy ryzyko utraty płynności finansowej chroniąc Twoje
              pieniądze przed oszustwami i nieuczciwymi kontrahentami.
            </p>
          </Space>
        </Col>
        <Col xs={24} md={8} style={{ textAlign: "center" }}>
          <br />
          <center>
            <img src="/img/svg/2.svg" alt="" />
          </center>
          <br />
        </Col>
        <Col md={4}></Col>
      </Row>
      <Row justify="space-between" align="middle" gutter={12}>
        <Col md={4}></Col>
        <Col md={8} style={{ padding: "30px 30px" }}>
          <Space direction="vertical">
            <p>
              taskizy.com to zaawansowane rozwiązanie, które integruje
              skomplikowane mechanizmy obsługi różnych scenariuszy transakcji
              oraz dziesiątki punktów decyzyjnych w jednej, łatwej do użycia
              usłudze. Naszym celem jest ułatwienie bezpiecznych transakcji
              internetowych. Dzięki taskizy.com możesz spokojnie i pewnie
              dokonywać płatności, zapewniając jednocześnie najwyższy poziom
              bezpieczeństwa swoim transakcjom online.
            </p>

            <Title level={2}>taskizy.com API</Title>
            <p>
              taskizy.com to nie tylko platforma do zabezpieczania transakcji,
              ale również zaawansowane API w chmurze, które umożliwia pełną
              komunikację z różnymi aplikacjami. Nasza platforma jest gotowa do
              współpracy z dowolnym oprogramowaniem, oferując wszechstronne
              możliwości integracji.
            </p>
            <p>
              Niezależnie od tego, czy potrzebujesz dodatkowej warstwy
              zabezpieczeń w swojej aplikacji, czy chcesz udostępnić funkcje
              płatności online, taskizy.com API jest gotowe do działania.
            </p>
            <p>
              Zapewniamy elastyczność, niezawodność i pełne wsparcie techniczne,
              aby ułatwić integrację i korzystanie z naszych usług. Zachęcamy do
              współpracy i eksplorowania możliwości, jakie oferuje taskizy.com
              API. Dzięki naszej platformie możesz zaoferować swoim klientom
              bezpieczne transakcje online oraz wygodne metody płatności.
            </p>
            <p>
              taskizy.com został ufundowany przez Andrzeja Bernata,
              doświadczonego programistę i architekta oprogramowania, który
              specjalizuje się w tworzeniu oprogramowania chmurowego
              dostarczanego jako usługi. Odpowiedzialny również za budowanie
              kluczowych modeli biznesowych, optymalizację kosztów i
              dostarczanie wartości klientom.
            </p>

            <Title level={2}>Kontakt</Title>
            <p>
              taskizy.com
              <br />
              ul. Wolności 64B
              <br />
              73-200 Choszczno
              <br />
              <br />
              NIP: 594-153-38-20
              <br />
              REGON: 320305252
              <br />
              <br />
              Jesteśmy płatnikiem VAT
              <br />
              <br />
              kontakt@postpay.pl
              <br />
              +48 530 858 861
            </p>
          </Space>
        </Col>
        <Col md={8} style={{ textAlign: "center" }}></Col>
        <Col md={4}></Col>
      </Row>
      <div className="partners">
        <img src="/img/svg/14.svg" />
      </div>
    </>
  );
};

export default AboutPage;
