import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoginPage from "../../../Login/LoginPage";
import {
  Card,
  Space,
  Row,
  Col,
  Breadcrumb,
  Descriptions,
  message,
  Tag,
  Divider,
  Spin,
  Alert,
  Typography,
} from "antd";
import {
  isLogged,
  destroyAuthToken,
  getAuthToken,
} from "../../../../helpers/account";
import { UserOutlined, HomeOutlined } from "@ant-design/icons";
import { api } from "../../../../config/api";
import EditAccountForm from "./Forms/EditAccountForm";
import { useSelector } from "react-redux";
const { Title, Text } = Typography;

function AccountDataPage() {
  const [data, setData] = useState([]); // Change initial state to []
  const [loading, setLoading] = useState(true);
  const accountDataChanged = useSelector((state) => state.accountDataChanged);

  useEffect(() => {
    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/accounts/${sessionStorage.getItem("uuid")}`;

    if (sessionStorage.getItem("authToken")) {
      // Fetch data from the API only if authToken exists

      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`, // Replace "Bearer" if your API expects a different prefix
        }),
      })
        .then((response) => response.json())
        .then((fetchedData) => {
          setData(fetchedData); // Set the data state with fetched data
          setLoading(false); // Set loading to false
        })
        .catch((error) => {
          setTimeout(() => {
            message.error("Dla bezpieczeństwa zostałeś wylogowany.");
            destroyAuthToken();
            window.location.href = "/transactions";
          }, 2000);
          setLoading(false); // Set loading to false even on error
        });
    }
  }, [accountDataChanged]); // Remove "data" from the dependency array

  if (isLogged()) {
    return (
      <>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Space direction="vertical">
              <Title level={2}>Moje dane</Title>
              <Breadcrumb
                items={[
                  {
                    href: "/transactions",
                    title: <HomeOutlined />,
                  },
                  {
                    href: "/account",
                    title: "Moje Dane",
                  },
                  {
                    title: "Dane konta oraz księgowe",
                  },
                ]}
              />
            </Space>
          </Col>
          <Col md={4} style={{ textAlign: "center" }}></Col>
          <Col md={4}></Col>
        </Row>
        <Divider></Divider>

        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <Space size={30} direction="vertical" style={{ width: "100%" }}>
              <Alert
                type="info"
                message="Poniższe dane będą prezentowane do kontrahentów oraz na Twoim publicznym profilu."
              ></Alert>
              <EditAccountForm />
              <Card size="small">
                <Descriptions layout="vertical">
                  <Descriptions.Item label="Typ konta">
                    {data.is_company ? "Firma" : "Osoba prywatna"}
                  </Descriptions.Item>

                  <Descriptions.Item label="Partner rozliczający">
                    {data && data.accountant ? data.accountant.name : "Brak"}
                  </Descriptions.Item>
                  <Divider />
                  <Descriptions.Item label="Reprezentant">
                    {data.fullname ? data.fullname : "(nie podano)"}
                  </Descriptions.Item>
                </Descriptions>
                <Divider />
                <Descriptions layout="vertical">
                  <Descriptions.Item label="Siedziba firmy">
                    <Space>
                      {data.address ? data.address : "(nie podano)"}{" "}
                      {data.post_code} {data.city}
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label="NIP i Nazwa firmy">
                    <Space>
                      {data.nip ? data.nip : "(nie podano)"} {data.company_name}
                    </Space>
                  </Descriptions.Item>
                  <Divider></Divider>
                  <Descriptions.Item label="Telefon">
                    <Space>
                      {data.mobile ? data.mobile : "(nie podano)"}{" "}
                      {data.mobile_confirmed ? (
                        <Tag color="green">Zweryfikowany</Tag>
                      ) : (
                        <Tag color="magenta">Niezweryfikowany</Tag>
                      )}
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label="E-mail">
                    <Space>
                      {data.email}{" "}
                      {data.email_confirmed ? (
                        <Tag color="green">Zweryfikowany</Tag>
                      ) : (
                        <Tag color="magenta">Niezweryfikowany</Tag>
                      )}
                    </Space>
                  </Descriptions.Item>
                  <Divider></Divider>
                  <Descriptions.Item label="Poziom dostępu">
                    <Space>
                      {data.access_level == 1 ? (
                        <>
                          <Tag color="magenta">Pracownik</Tag>{" "}
                          <Link to="/admin/transactions">
                            Narzędzia pracownika
                          </Link>
                        </>
                      ) : (
                        <Tag color="green">Użytkownik regularny</Tag>
                      )}
                    </Space>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Space>
          </Col>
          <Col md={4}></Col>
        </Row>
      </>
    );
  } else {
    return <LoginPage></LoginPage>;
  }
}

export default AccountDataPage;
