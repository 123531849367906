import {
  Button,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Divider,
  Card,
  FloatButton,
  Tag,
} from "antd";
import React, { useState, useEffect } from "react";
import { RoughNotation } from "react-rough-notation";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Catalog } from "./CatalogPage";
import {
  SoundTwoTone,
  BuildTwoTone,
  DollarTwoTone,
  ContactsTwoTone,
  LikeTwoTone,
  BookTwoTone,
  ShopTwoTone,
} from "@ant-design/icons";

import HelpBox from "./HelpBox";
import { Inquiries } from "./InquiriesPage";
import PromotedProducts from "./PromotedProducts";
import MarketplacePromotedProductsList from "../Marketplace/MarketplacePromotedProductsList";
import RecentAccountsList from "../Marketplace/RecentAccountsList";
import { Assets } from "./AssetsPage";
import PromotedCategoryProducts from "./PromotedCategoryProducts";
import RecentProfilesList from "../Marketplace/RecentProfilesList";
import LastFiveInquiries from "./LastFiveInquiries";
const { Title } = Typography;

const WelcomePage = () => {
  const [visible, setVisible] = useState(null);

  const questions = [
    {
      key: "1",
      label: "Czy taskizy.com zabezpiecza transakcje o małej wartości?",
      content: (
        <p>
          taskizy.com, zapewniając ochronę i bezpieczeństwo, został
          zoptymalizowany przede wszystkim dla transakcji powyżej 500 zł, co
          oznacza, że jego mechanizmy ochronne są najskuteczniejsze w przypadku
          większych kwot.
        </p>
      ),
    },
    {
      key: "2",
      label:
        "Czy uzyskam fakturę za usługę zabezpieczenia transakcji przez taskizy.com?",
      content: (
        <p>
          Oczywiście. Jako firma działająca zgodnie z polskim prawem, jesteśmy
          zobowiązani do wystawienia faktury za świadczone usługi zabezpieczenia
          transakcji. Dzięki temu otrzymasz dokument potwierdzający dokonaną
          transakcję, który będzie spełniał wymogi i przepisy polskiego prawa.
        </p>
      ),
    },
    {
      key: "4",
      label:
        "Wpłaciłem depozyt do taskizy.com ale sprzedawca przysłał mi towar niezgodny z umową, co wtedy?",
      content: (
        <p>
          Jeśli wpłaciłeś depozyt do taskizy.com, a sprzedawca dostarczył ci
          towar niezgodny z umową, to masz możliwość rozwiązania takiej
          sytuacji. Pierwszym krokiem jest kontakt z sprzedawcą w celu
          wyjaśnienia sytuacji. Jeśli nie jesteś w stanie osiągnąć
          satysfakcjonującego rozwiązania z sprzedawcą, skontaktuj się z
          taskizy.com i zgłoś spór, opisując sytuację i przekazując dowody na
          niezgodność towaru z umową. taskizy.com będzie starał się pomóc w
          rozwiązaniu sporu, analizując dostępne dowody i dążąc do
          sprawiedliwego rozwiązania. taskizy.com działa jako pośrednik i stara
          się zapewnić uczciwe rozstrzygnięcie sporu, co chroni Twoje interesy w
          przypadku problemów z transakcją.
        </p>
      ),
    },
    {
      key: "5",
      label: "Jakie sposoby dostawy są wspierane przez taskizy.com",
      content: (
        <p>
          taskizy.com wspiera sposoby dostawy, które umożliwiają śledzenie
          przesyłki za pomocą numeru identyfikacyjnego. Oto opis obsługiwanych
          sposobów dostawy: Orlen Paczka, Paczkomaty Inpost, DPD, FedEx, Kurier
          InPost, Pocztex, DHL, Poczta Polska, GLS, Paczka w Ruchu, UPS, Odbiór
          osobisty, E-mail.
        </p>
      ),
    },
    {
      key: "6",
      label: "Jakie rodzaje umów wspiera taskizy.com",
      content: (
        <p>
          taskizy.com jest elastyczną platformą, która umożliwia stronom pełną
          dowolność w tworzeniu i definiowaniu swoich umów. Jednak dla wygody
          użytkowników udostępniamy również 17 gotowych wzorów umów, które można
          wykorzystać natychmiastowo. Dzięki temu masz możliwość dostosowania
          umowy do swoich indywidualnych potrzeb lub skorzystania z jednego z
          naszych gotowych szablonów, co zapewnia szybkość i wygodę w zawieraniu
          transakcji. Dostępne wzory umów:{" "}
          {[
            "Umowa o wykonanie rękodzieła",
            "Umowa o  sprzedaż sklepu internetowego",
            "Umowa o usługi aktualizacyjne/poprawkowe serwera",
            "Umowa o dostarczenie tekstów seo",
            "Umowa o wykonanie audytu technicznego strony",
            "Umowa o wykonanie audytu seo",
            "Umowa o wykonanie logo, plakatu i grafiki",
            "Umowa o wykonanie modyfikacji w sklepie/stronie",
            "Umowa o wykonanie robót budowlanych",
            "Umowa o sprzedaż profilu na Instagram",
            "Umowa o wykonanie sklepu internetowego",
            "Umowa o sprzedaż roweru",
            "Umowa o sprzedaż grupy Facebook",
            "Umowa o sprzedaż antyków z poświa. autentyczności",
            "Umowa o sprzedaż samochodu",
            "Umowa o sprzedaż koparki",
            "Umowa prosta",
          ].map((item, index) => (
            <span
              key={index}
              style={{
                margin: "5px",
                display: "inline-block",
                background: "#eee",
                padding: "2px 8px",
                borderRadius: "4px",
              }}
            >
              {item}
            </span>
          ))}
        </p>
      ),
    },
  ];

  return (
    <>
      {" "}
      <Helmet>
        <title>
          Kup usługę, dodaj zapytanie ofertowe lub znajdź swoje następne
          zlecenie bez ryzyka oszustwa.
        </title>
      </Helmet>
      <Space direction="vertical">
        <Row align="middle" style={{ minHeight: "500px" }} className="header">
          <Col md={4}></Col>
          <Col md={8} style={{ padding: "0px 15px", height: "320px" }}>
            <LastFiveInquiries />
          </Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <Space size={20} direction="vertical" style={{ width: "100%" }}>
              <Title level={2} style={{ margin: 0 }} className="welcome">
                Zlecenia{" "}
                <RoughNotation
                  style={{ color: "#000" }}
                  type="highlight"
                  show={true}
                >
                  z gwarancją
                </RoughNotation>{" "}
                wypłaty
              </Title>
              <Tag color="geekblue" style={{ fontWeight: 400 }}>
                Platforma dla firm i freelancerów
              </Tag>
              <p style={{ margin: "auto" }}>
                Bezpieczny marketplace zdalnych usług z Escrow oraz{" "}
                <Link to="/taskizybot">Asystentem AI</Link> projektu + codzienna paczka
                nowych zleceń prosto na e-mail.
              </p>

              <Link to="/account/register">
                <Button size="large" type="primary">
                  Utwórz profil za darmo
                </Button>
              </Link>
              <Link to="/join">Dlaczego warto?</Link>
              <br />
            </Space>
          </Col>

          <Col md={4}></Col>
        </Row>
        <Row justify="space-between" align="middle">
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <Space direction="vertical">
              <center>
                <Title level={2}>Co chcesz teraz zrobić?</Title>
                <Space size={10}>
                  <p style={{ textAlign: "center" }}>
                    <Space direction="vertical">
                      <Button
                        style={{ width: "110px", height: "110px" }}
                        shape="circle"
                      >
                        <Link to="/marketplace/freelancers">
                          <Space direction="vertical">
                            <b>
                              <u>
                                Znaleźć
                                <br />
                                wykonawcę
                              </u>
                            </b>
                          </Space>
                        </Link>
                      </Button>
                    </Space>
                  </p>
                  <p style={{ textAlign: "center" }}>
                    <Space direction="vertical">
                      <Button
                        style={{ width: "110px", height: "110px" }}
                        shape="circle"
                      >
                        <Link to="/marketplace/inquiries">
                          <Space direction="vertical">
                            <b>
                              <u>
                                Pozyskać
                                <br />
                                zlecenie
                              </u>
                            </b>
                          </Space>
                        </Link>
                      </Button>
                    </Space>
                  </p>

                  <p style={{ textAlign: "center" }}>
                    <Space direction="vertical">
                      <Button
                        style={{ width: "110px", height: "110px" }}
                        shape="circle"
                      >
                        <Link to="/marketplace/assets">
                          <Space direction="vertical">
                            <b>
                              <u>
                                Sprzedać
                                <br />
                                stronę
                              </u>
                            </b>
                          </Space>
                        </Link>
                      </Button>
                    </Space>
                  </p>
                </Space>
                <br />
                <br />
              </center>

              <Divider></Divider>
              <center>
                <Title level={2}>
                  Dlaczego <img src="/img/svg/logo-2.svg" /> ?
                </Title>
              </center>
              <Row gutter={12} align="middle">
                <Col md={4}></Col>
                <Col md={16}>
                  <img src="/img/svg/9.svg" alt="" style={{ width: "100%" }} />
                </Col>
                <Col md={4}></Col>
                <Col md={12}>
                  <Space direction="vertical">
                    <Title level={4}>Transakcja z Ochroną Kupującego</Title>
                    <p>
                      Autorski mechanizm Escrow zbudowany przez taskizy.com
                      skutecznie zabezpiecza transakcje, chroniąc wykonawców
                      przed nierzetelnymi zamawiającymi, a zamawiających przed
                      nieuczciwymi wykonawcami.
                    </p>
                  </Space>
                </Col>
                <Col md={12}>
                  <Space direction="vertical">
                    <Title level={4}>Gwarancja wypłaty wynagrodzenia</Title>
                    <p>
                      Wykonując zlecenie przez taskizy.com, masz pewność, że
                      Twoje wynagrodzenie jest zabezpieczone. Dzięki naszemu
                      mechanizmowi Escrow środki są przechowywane do momentu
                      wykonania usługi, zapewniając Ci pełne bezpieczeństwo
                      transakcji.
                    </p>
                  </Space>
                </Col>
              </Row>
              <Row gutter={12} align="middle">
                <Col md={12}>
                  <Space direction="vertical">
                    <Title level={4}>
                      Wsparcie TaskizyAI w każdej transakcji
                    </Title>
                    <p>
                      Inteligentny asystent AI, który pomaga użytkownikom w
                      komunikacji, odpowiadając na pytania dotyczące umów,
                      terminów czy płatności. Wystarczy wspomnieć go w
                      wiadomości, aby uzyskać szybkie i precyzyjne wsparcie w
                      czasie rzeczywistym.
                    </p>
                    <Link to="/taskizybot">Więcej o TaskizyAI</Link>
                  </Space>
                </Col>
                <Col md={12}>
                  <Space direction="vertical">
                    <Title level={4}>
                      Chat, lista zadań i wysyłanie załączników
                    </Title>
                    <p>
                      Szybkie wymienianie informacji oraz plików, co ułatwia
                      współpracę w trakcie realizacji zleceń. Dzięki integracji
                      z systemem transakcji, każda wiadomość i załącznik są
                      przypisane do konkretnego projektu, co zapewnia pełną
                      przejrzystość i kontrolę nad procesem.
                    </p>
                  </Space>
                </Col>
              </Row>
              <Row gutter={12} align="middle">
                <Col md={24} style={{ textAlign: "center" }}>
                  <Divider />
                  <Space direction="vertical">
                    <Title level={4}>
                      Prace rozliczamy z zaufanym Partnerem - Kugaru
                    </Title>
                    <p>
                      Z Kugaru z łatwością rozliczysz się z każdym
                      Zleceniodawcą. W dowolnym czasie i z dowolnej lokalizacji.
                      Całkowicie legalnie. Bez zbędnych formalności oraz
                      konieczności prowadzenia działalności gospodarczej.
                    </p>
                  </Space>
                </Col>
              </Row>
            </Space>
          </Col>

          <Col md={4}></Col>
        </Row>
        <Divider></Divider>

        <Row justify="space-between" align="middle">
          <Col md={4}></Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Space>
                <img src="/img/svg/23.svg" style={{ width: "50px" }} />

                <Title level={3}>Ostatnio dodane zlecenia</Title>
              </Space>
              <Card size="small" style={{ backgroundColor: "#00A556" }}>
                <Space direction="horizontal">
                  Oferujesz usługi na najwyższym poziomie?{" "}
                  <Link to="/transactions">
                    <Button type="default">Zacznij zdobywać klientów</Button>
                  </Link>
                </Space>
              </Card>
              <Inquiries limit={5} />
              <br />
              <center>
                <Link to={`/marketplace/inquiries`}>
                  Zobacz wszystkie zapytania ofertowe
                </Link>
              </center>
            </Space>
          </Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <br />
            <Space direction="vertical">
              <img src="/img/svg/app.svg" style={{ width: "100%" }} />
            </Space>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row justify="space-between" align="middle">
          <Col md={4}></Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Space>
                <img src="/img/svg/22.svg" style={{ width: "50px" }} />

                <Title level={3}>
                  Ostatnio dodane strony i sklepy na sprzedaż
                </Title>
              </Space>
              <Card size="small" style={{ backgroundColor: "#1677ff" }}>
                <Space direction="horizontal">
                  Masz działający biznes lub stronę internetową na sprzedaż?{" "}
                  <Link to="/assets">
                    <Button type="default">Wystaw swoją ofertę</Button>
                  </Link>
                </Space>
              </Card>
              <Assets limit={3} />
              <br />
              <center>
                <Link to={`/marketplace/assets`}>Zobacz wszystkie oferty</Link>
              </center>
            </Space>
          </Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <br />
            <Space direction="vertical">
              <img src="/img/svg/18.svg" style={{ width: "100%" }} />
            </Space>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row justify="space-between" align="middle">
          <Col md={4}></Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Space>
                <img src="/img/svg/24.svg" style={{ width: "50px" }} />

                <Title level={3}>Zamów usługę z pewnością wykonania</Title>
              </Space>
              <Card size="small" style={{ backgroundColor: "#FF8800" }}>
                <Space direction="horizontal">
                  Oferujesz usługi na najwyższym poziomie?{" "}
                  <Link to="/transactions">
                    <Button type="default">Zacznij zdobywać klientów</Button>
                  </Link>
                </Space>
              </Card>
              <MarketplacePromotedProductsList />
              <br />
              <center>
                <Link to={`/offers`}>Znajdź więcej usług</Link>
              </center>
            </Space>
          </Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <br />
            <Space direction="vertical">
              <img src="/img/svg/process.svg" style={{ width: "100%" }} />
            </Space>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row justify="space-between" align="top">
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Row gutter={12}>
              <Col md={12}>
                <Space direction="vertical">
                  <Title level={3}>
                    Jak działa <img src="/img/svg/logo-2.svg" /> ?
                  </Title>
                  <p>
                    taskizy.com to zautomatyzowana platforma do zawierania
                    bezpiecznych transakcji internetowych “1 na 1” dla osób
                    prywatnych i firm.
                  </p>
                  <p>
                    W taskizy.com tworzysz transakcje, w których ustalasz
                    warunki. Na te warunki Ty i Twój kontrahent musicie się
                    zgodzić aby transakcja przeszła do kolejnego etapu.
                    Następnie warunki muszą zostać wypełnione przez strony
                    transakcji.
                  </p>
                  <a href="/about">
                    <Button>Więcej o taskizy.com</Button>
                  </a>
                </Space>
              </Col>

              <Col md={12}>
                <Space direction="vertical">
                  <Title level={3}>Najczęściej zadawane pytania</Title>
                  {questions.map((question) => (
                    <div key={question.key} style={{ marginBottom: "16px" }}>
                      <a
                        href=""
                        style={{ width: "300px", overflowX: "hidden" }}
                        type="link"
                        onClick={(e) => {
                          e.preventDefault();
                          setVisible(question.key);
                        }}
                      >
                        {question.label}
                      </a>
                      <Modal
                        title={question.label}
                        visible={visible === question.key}
                        onCancel={() => setVisible(null)}
                        footer={[
                          <Button key="close" onClick={() => setVisible(null)}>
                            Zamknij
                          </Button>,
                        ]}
                      >
                        {question.content}
                      </Modal>
                    </div>
                  ))}
                </Space>
              </Col>
            </Row>
          </Col>

          <Col md={4}></Col>
        </Row>
      </Space>
      <Link to="/inquiries">
        <FloatButton
          type="primary"
          style={{ width: "70px", height: "70px" }}
          description="Dodaj zlecenie"
          onClick={() => console.log("onClick")}
        />
      </Link>
    </>
  );
};

export default WelcomePage;
