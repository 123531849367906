import {
  Button,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Divider,
  Card,
  Spin,
  Table,
  Tag
} from "antd";
import React, { useState, useEffect } from "react";
import { api } from "../../config/api";
import { formatPolishCurrency } from "../../helpers/currency";
import { formatPolishDate } from "../../helpers/date";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";

const { Title } = Typography;

const columns = [
 
  {
    title: "Przedmiot zapytania",
    dataIndex: "name",
    key: "name",
    fixed: "left",
    render: (name, record) => (
      <>
        <Space direction="vertical">
          <Title level={5} style={{ margin: 0 }}><u style={{ cursor: "pointer" }}>{name}</u></Title>
          <Space direction="vertical">
            <Tag>{record.account.company_name ? record.account.company_name : "Osoba prywatna"}</Tag>
            <Space>
              <AvatarDisplay account={record.account} src={record.account.photo} size={32} /> {record.account.fullname}
            </Space>
          </Space>
        </Space>
      </>
    ),
  },
  {
    title: "Budżet",
    dataIndex: "budget",
    key: "budget",
    sorter: true,
    render: (budget) => {
      return (<>
        <Tag color="gold-inverse">{formatPolishCurrency(budget)}</Tag>
      </>)

    },
  },
];

export const Inquiries = ({ limit, paginationLimit }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // API endpoint URL
    const apiUrl = `${api.host}/inquiries/active`;

    // Fetch data from the API
    fetch(apiUrl, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const limitedData = limit ? data.slice(0, limit) : data; // Apply limit
        setData(limitedData); // Update the state with the limited data
        setLoading(false); // Set loading to false
      });
  }, [limit]);

  return (



    <Card size="small">

      <Table
        onRow={(record) => ({
          onClick: () => {
            window.location.href = `/marketplace/inquiry/${record.uuid}`;
          },
        })}
        className="shadow"
        columns={columns}
        dataSource={data}
        size="small"
        pagination={{
          pageSize: paginationLimit || 10, // Default page size is 10
          showSizeChanger: true, // Allow user to adjust the page size
          pageSizeOptions: ["5", "10", "20", "50"], // Options for page size
          locale: { items_per_page: "na stronę" }, // Optional: Polish translation
        }}
        locale={{
          emptyText: "Brak zapytań ofertowych, zajrzyj jutro.",
        }}

      />

    </Card>



  );
};
