import React, { useState, useEffect } from "react";
import { api } from "../../config/api";
import {
    Card,
    Space,
    Row,
    Col,
    Typography,
    Divider,
    List,
    Spin,
    Tag
} from "antd";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { formatPolishCurrency } from "../../helpers/currency";
import { formatPolishDate } from "../../helpers/date";
import { isLogged } from "../../helpers/account";
import DeleteInquiryResponseButton from "../Inquiry/Forms/DeleteInquiryResponseButton";
import {
    ApiTwoTone
} from "@ant-design/icons";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";

const { Title, Text } = Typography;

const MarketplaceInquiryResponsesList = ({ inquiryUuid }) => {
    const [loading, setLoading] = useState(true);
    const [responses, setResponses] = useState([]);
    const { inquiryUuid: routeInquiryUuid } = useParams();

    const finalInquiryUuid = inquiryUuid || routeInquiryUuid;

    // Redux state for listening to inquiry response events
    const inquiryResponseCreated = useSelector(
        (state) => state.inquiryResponseCreated
    );
    const inquiryResponseDeleted = useSelector(
        (state) => state.inquiryResponseDeleted
    );

    const fetchResponses = () => {
        if (!finalInquiryUuid) return;

        const apiUrl = `${api.host}/inquiries/${finalInquiryUuid}/responses/public`;

        setLoading(true);
        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch inquiry responses");
                }
                return response.json();
            })
            .then((data) => {
                setResponses(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching responses:", error);
                setLoading(false);
            });
    };

    // Fetch responses on mount and when actions are dispatched
    useEffect(() => {
        fetchResponses();
    }, [finalInquiryUuid, inquiryResponseCreated, inquiryResponseDeleted]);

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Row>
                <Col span={24}>

                    <Title level={5}><ApiTwoTone /> Odpowiedzi na zapytanie</Title>

                    <Spin spinning={loading}>
                        {responses.length > 0 ? (
                            <List
                                itemLayout="vertical"
                                dataSource={responses}
                                bordered={false}
                                renderItem={(response) => (
                                    <List.Item>
                                        <Card>


                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <Text strong>Cena:</Text>{" "}
                                                    <i>Ukryta</i>
                                                </Col>
                                                <Col span={8}>
                                                    <Text strong>Czas realizacji:</Text>{" "}
                                                    {response.delivery_days} dni
                                                </Col>
                                                <Col span={8}>
                                                    <Text strong>Dodano:</Text>{" "}
                                                    {formatPolishDate(response.created_at)}
                                                </Col>
                                            </Row>
                                            <Divider />
                                            <Space direction="vertical" style={{width:"100%"}}>
                                                <Text>{response.message}</Text>
                                                <Row gutter={16} align={"middle"}>
                                                    <Col>
                                                        <Space><AvatarDisplay account={response.account} src={response.account.photo} size={32} /> {response.account.fullname} <Tag>{response.account.company_name ? response.account.company_name : "Osoba prywatna"}</Tag></Space>
                                                    </Col>
                                                </Row>

                                                {isLogged() &&
                                                    sessionStorage.getItem("uuid") ===
                                                    response.account.uuid ? (
                                                    <DeleteInquiryResponseButton
                                                        inquiryUuid={finalInquiryUuid}
                                                        responseUuid={response.uuid}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </Space>

                                        </Card>
                                    </List.Item>
                                )}
                            />
                        ) : (
                            <Text>Brak odpowiedzi na to zapytanie.    <Link
                                to="/transactions"
                            >
                               <u>Dodaj jako pierwszy!</u>
                            </Link></Text>
                        )}
                    </Spin>

                </Col>
            </Row>
        </Space>
    );
};

export default MarketplaceInquiryResponsesList;
