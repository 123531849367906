import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import LoginPage from "../../../Login/LoginPage";
import { Switch, Card, Space, Row, Col, Breadcrumb, Spin, message, Alert, Descriptions, Typography, Divider, Table } from "antd";
import { isLogged, getAuthToken } from "../../../../helpers/account";
import { api } from "../../../../config/api";
import { UnorderedListOutlined, HomeOutlined, EuroCircleTwoTone, CalendarTwoTone } from "@ant-design/icons";
import { formatPolishCurrency } from "../../../../helpers/currency";
const { Title } = Typography;
function AccountProductPage() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [accountProductLoading, setAccountProductLoading] = useState(false);
    const [isOffered, setIsOffered] = useState(false);
    const [accountProductUUID, setAccountProductUUID] = useState(null);

    const { productUuid } = useParams();

    const categoryColumns = [
        {
            title: "Nazwa",
            dataIndex: "name",
            key: "name",
            fixed: "left",
            sorter: true,
            render: (name, record) => (
                <>
                    <Link to={`/account/offer/category/${record.uuid}`}>{name}</Link>
                </>
            ),
        },
    ];

    const handleAccountProductState = async (state) => {
        try {
            if (isLogged()) {

                setAccountProductLoading(true);
                if (state) {
                    // If the product is offered, create a record in the database
                    await fetch(`${api.host}/accounts/products`, {
                        method: "POST",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            Authorization: `${getAuthToken()}`,
                        }),
                        body: JSON.stringify({
                            uuid: productUuid
                        })
                    });
                    message.success("Zmiany zostały zapisane.");
                } else {
                    // If the product is not offered, delete the record from the database
                    await fetch(`${api.host}/accounts/products/${accountProductUUID}`, {
                        method: "DELETE",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            Authorization: `${getAuthToken()}`,
                        }),
                    });
                    message.success("Zmiany zostały zapisane.");
                }
                fetchData();

            }
        } catch (error) {
            console.error("Error updating product state:", error);
        }
    }

    const fetchData = async () => {
        try {
            if (isLogged()) {
                setAccountProductLoading(true);
                const productResponse = await fetch(`${api.host}/products/${productUuid}`, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: `${getAuthToken()}`,
                    }),
                });
                const productData = await productResponse.json();
                setData(productData);

                const assignedProductsResponse = await fetch(`${api.host}/accounts/products`, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: `${getAuthToken()}`,
                    }),
                });

                const assignedProductsData = await assignedProductsResponse.json();

                const assignedProduct = assignedProductsData.find(accountProduct => accountProduct.product.uuid === productUuid);
                if (assignedProduct) {
                    setIsOffered(true);
                    setAccountProductUUID(assignedProduct.uuid);
                } else {
                    setIsOffered(false);
                    setAccountProductUUID(null);
                }
                // Set loading to false after fetching the data
                setAccountProductLoading(false);
            }
        } catch (error) {
            console.error("Error fetching product data:", error);
            setAccountProductLoading(false);
        }
    };

    useEffect(() => {
        fetchData();

        fetch(`${api.host}/categories`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `${getAuthToken()}`,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                setCategories(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching categories data:", error);
                setLoading(false);
            });

    }, [productUuid]);

    if (isLogged()) {
        return (
            <>


                <Space direction="vertical" style={{ width: "100%" }}>
                    <Row>
                        <Col md={4}></Col>
                        <Col md={16} style={{ padding: "15px 15px" }}>
                            <Space direction="vertical">
                                <Title level={2}>
                                    {data.name}
                                </Title>

                                <Breadcrumb
                                    items={[
                                        {
                                            href: "/transactions",
                                            title: <HomeOutlined />,
                                        },
                                        {
                                            href: "/account/offer",
                                            title: (
                                                <>
                                                    <UnorderedListOutlined />
                                                    <span>Moja oferta</span>
                                                </>
                                            ),
                                        },
                                        {
                                            title: "Produkty",
                                        },
                                    ]}
                                />
                            </Space>
                        </Col>
                        <Col md={4} style={{ textAlign: "center" }}>

                        </Col>
                        <Col md={4}></Col>
                    </Row>
                    <Divider></Divider>

                    <Row>
                        <Col md={4}></Col>
                        <Col md={16} style={{ padding: "15px 15px" }}>


                            <Row gutter={12}>
                                <Col md={16}>
                                    <Space direction="vertical">

                                        <p
                                            style={{ lineHeight: "185%" }}
                                            dangerouslySetInnerHTML={{
                                                __html: data.description
                                                    ? data.description.replace(/\n/g, "")
                                                    : "",
                                            }}
                                        ></p>


                                        <Descriptions layout="vertical" bordered>
                                            <Descriptions.Item
                                                key="1"
                                                label={
                                                    <>
                                                        Orientacyjna cena wykonania usługi
                                                    </>
                                                }
                                            >
                                                <EuroCircleTwoTone /> {formatPolishCurrency(data.price)}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                key="2"
                                                label={
                                                    <>
                                                        Orientacyjny czas wykonania w dniach
                                                    </>
                                                }
                                            >
                                                <CalendarTwoTone /> {data.delivery_days}
                                            </Descriptions.Item>
                                        </Descriptions>

                                        <br />
                                    </Space>
                                </Col>
                                <Col md={8}>
                                    <Space style={{ width: "100%" }}>
                                        <Spin spinning={accountProductLoading}>
                                            <Card>
                                                <Space direction="vertical">
                                                    <Alert message="Aktywacja zapewni Twojej firmie widoczność w katalogu usług i produktów, trafiając do firm poszukujących dokładnie takiej współpracy.">

                                                    </Alert>
                                                    <Space>
                                                        <Switch checked={isOffered} onChange={handleAccountProductState} />
                                                        <p>
                                                            Oferuj tą usługę / produkt
                                                        </p>
                                                    </Space>
                                                </Space>
                                            </Card>
                                        </Spin>

                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                        </Col>
                        <Col md={4}></Col>
                    </Row>
                    <Row>
                        <Col md={4}></Col>
                        <Col md={16} style={{ padding: "0px 15px" }}>
                            <Space size={30} direction="vertical" style={{ width: "100%" }}>
                                <Alert
                                    showIcon
                                    message="Wybierz kategorie usług, które oferujesz, a następnie aktywuj swoje oferty w katalogu taskizy.com, aby zainteresowani klienci mogli je u Ciebie zamawiać. Proces aktywacji jest całkowicie darmowy. Oferty automatycznie pojawią się również na Twoim profilu publicznym.">

                                </Alert>
                                <Card size="small">
                                    <Table
                                        pagination={{ pageSize: 5 }}
                                        className="shadow"
                                        columns={categoryColumns}
                                        dataSource={categories}
                                        loading={loading}
                                        locale={{
                                            emptyText: "Brak kategorii usług",
                                        }}
                                    />
                                </Card>
                            </Space>
                        </Col>
                        <Col md={4}></Col>
                    </Row>


                </Space>
            </>
        );
    } else {
        return <LoginPage />;
    }
}

export default AccountProductPage;
