/**
 * Validates an email address using a regular expression.
 * @param {string} email - The email address to validate.
 * @returns {boolean} Returns true if the email is valid, otherwise false.
 */
export const isEmailValid = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
};

/**
 * Validates an IBAN (International Bank Account Number) using a regular expression.
 * @param {string} iban - The IBAN to validate.
 * @returns {boolean} Returns true if the IBAN is valid, otherwise false.
 */
export const isIBANValid = (iban) => {
    // Regular expression for IBAN validation
    const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}$/;
    return ibanRegex.test(iban);
};

/**
 * Checks if a password meets certain security criteria using a regular expression.
 * @param {string} password - The password to check.
 * @returns {boolean} Returns true if the password meets the criteria, otherwise false.
 */
export const isPasswordSafe = (password) => {
    // Regular expression for password validation
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/;
    return passwordRegex.test(password);
};

/**
 * 
 * @param {*} url 
 * @returns 
 */
export const isValidUrl = (url) => {
    const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
    return urlRegex.test(url);
};