import {
  Button,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Divider,
  Card,
  Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { RoughNotation } from "react-rough-notation";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import HelpBox from "./HelpBox";
const { Title } = Typography;

const TaskizybotPage = () => {
  const [visible, setVisible] = useState(null);

  return (
    <>
      {" "}
      <Helmet>
        <title>Wirtualny manager taskizy.com</title>
      </Helmet>
      <Row justify="space-between" align="middle" gutter={12}>
        <Col md={4}></Col>
        <Col md={8} style={{ padding: "0px 15px" }}>
          <Space direction="vertical">
            <Title level={2}>
              TaskizyAI pomaga unikać błędów w umowach i w komunikacji zadań
            </Title>
          </Space>
        </Col>
        <Col md={8} style={{ textAlign: "center", padding: "15px" }}>
          <Link to="/account/register">
            <Button size="large" type="primary">
              Utwórz profil za darmo
            </Button>
          </Link>
        </Col>
        <Col md={4}></Col>
      </Row>
      <Row align={"top"}>
        <Col md={4}></Col>
        <Col md={8} style={{ padding: "0px 15px" }}>
          <Space direction="vertical">
            <Title level={3}>
              TaskizyAI to wspierany sztuczną inteligencją asystent dostępny dla
              każdej transakcji zawieranej na platformie. Począwszy od analizy
              warunków umowy, a kończąc na analizie rozmów z chat podpowiada jak
              uniknąć problemów.
            </Title>

            <p>
              TaskizyAI to wspierany sztuczną inteligencją asystent, dostępny
              przy każdej transakcji na platformie, który pomaga w efektywnym
              zarządzaniu projektami i minimalizowaniu ryzyka. Począwszy od
              analizy warunków umowy, asystent identyfikuje potencjalne
              nieścisłości, proponuje precyzyjne zapisy oraz sugeruje poprawki,
              aby uniknąć nieporozumień między stronami. W trakcie współpracy
              TaskizyAI analizuje rozmowy w czacie, wychwytując kluczowe
              szczegóły i zapewniając, że zadania są jasno zdefiniowane i zgodne
              z wcześniejszymi ustaleniami.
            </p>
            <p>
              Dzięki temu funkcja ta pomaga freelancerom i klientom unikać
              typowych błędów, takich jak niedoprecyzowanie wymagań czy
              pominięcie istotnych aspektów w komunikacji, co przekłada się na
              większe zaufanie i lepszą jakość realizowanych projektów.
            </p>
          </Space>
        </Col>
        <Col md={8} style={{ textAlign: "center", padding: "15px 15px" }}>
          <Space size={30} direction="vertical">
            <img src="/img/svg/34.svg" alt="" style={{ width: "100%" }} />
          </Space>
        </Col>
        <Col md={4}></Col>
      </Row>
    </>
  );
};

export default TaskizybotPage;
