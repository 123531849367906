import React, { useState, useEffect } from "react";
import { api } from "../../config/api";
import {
    Card, Space, Row, Col, Breadcrumb, Spin,
    Typography,
    Tag,
    Avatar
} from "antd";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { formatPolishCurrency } from "../../helpers/currency";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";
const { Title } = Typography;

const MarketplacePromotedProductsList = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const apiUrl = `${api.host}/products/promoted`;

        // Fetch data from the API
        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, []);


    return (

        <>

            <Space direction="vertical" style={{ width: "100%" }}>
                <Row>
                    <Col md={24} >
                        <Spin spinning={loading}>
                            <Space direction="vertical" style={{ width: "100%" }}>
                                {data.map((product) => (
                                    <Card key={product.uuid}>
                                        <Link to={`/marketplace/product/${product.uuid}`}>
                                            <Title level={5}>
                                                {product.name}
                                            </Title>
                                        </Link>
                                        <Space direction="vertical">
                                            <div>
                                                Orientacyjny czas realizacji: {product.delivery_days} dni
                                            </div>
                                            <div>
                                                Orientacyjna cena: <Tag color="gold-inverse">{formatPolishCurrency(product.price)}</Tag>
                                            </div>
                                            <div>
                                                <Space>
                                                    Usługa oferowana przez <Link to={`/marketplace/product/${product.uuid}`}>{product.account_products.length} firmy</Link>
                                                </Space>
                                                {" "}
                                                <Space>
                                                    <Avatar.Group
                                                        maxCount={7}
                                                        size="large"

                                                    >
                                                        {product.account_products.map((account, index) => (
                                                            <AvatarDisplay account={account.account} src={account.account.photo} size={32} />
                                                        ))}
                                                    </Avatar.Group>
                                                </Space>

                                            </div>
                                        </Space>
                                    </Card>
                                ))}
                            </Space>
                        </Spin>
                    </Col>
                </Row>
            </Space>
        </>
    );
};

export default MarketplacePromotedProductsList;
