import React, { useState, useEffect } from "react";
import { Steps } from "antd";

const ProgressBarTransactionMode7 = (props) => {
  const [currentStage, setCurrentStage] = useState(0);

  useEffect(() => {
    if (props.transaction) {
      if (
        props.transaction.agreement_accepted_by_counterparty === true &&
        props.transaction.agreement_accepted_by_initiator === true
      ) {
        setCurrentStage(1);

        if (props.transaction.escrow_received) {
          setCurrentStage(2);

          if (props.transaction.finished) {
            setCurrentStage(4);
          }
        }
      }
    }
  }, [props.transaction]);

  const steps = [
    {
      title: "Umowa",
      description: "Uzgodnienie i akceptacja warunków",
    },
    {
      title: "Depozyt",
      description: "Zabezpieczenie środków na transakcję",
    },
    {
      title: "Dostawa",
      description: "Brak dostarczenia towaru lub usługi",
      status: "error",
    },
    {
      title: "Zakończenie transakcji",
      description: "Zwrot środków",
    },
  ];

  if (
    props.transaction !== null &&
    (props.transaction.transaction_mode_id !== 7 && props.transaction.transaction_mode_id !== 2)
  ) {
    return <></>;
  }

  if (props.transaction !== null) {
    return <Steps size="small" current={currentStage} items={steps} />;
  }
};

export default ProgressBarTransactionMode7;
